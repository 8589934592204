import logo from './logo.svg';
import './App.css';
import React, {useState} from 'react';
import Amadeus from './components/Amadeus';
import {IoInformationCircleOutline, IoHelpCircleOutline, IoDownloadOutline, IoVolumeHigh, IoVolumeMedium, IoVolumeLow, IoVolumeMute} from "react-icons/io5";
import Slider, {Range} from 'rc-slider';
import 'rc-slider/assets/index.css';
function App() {

  return (
    <div style={{height: '100vh', display: 'flex', flexDirection: 'column',     }}>
      
      <Amadeus/>
    </div>
  );
}

export default App;