import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
// import Tooltip from 'rc-tooltip';

const useStylesBootstrap = makeStyles((theme) => ({

    tooltip: {
        backgroundColor: theme.palette.common.black,
        fontSize: 18,
        border: '1px solid #dadde9',
        borderRadius: '10px',
        padding: 16,
        boxShadow: 'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px',
        borderColor: "rgba(255, 255, 255, 0.2)", backgroundColor: "#2D0C28", borderWidth: 3, 
        textAlign: 'center',
    },
}));

function MyToolTip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip enterDelay={100} enterNextDelay={100} classes={classes} {...props} interactive />;
}

function append(arr, el) {
    let arrCpy = [];
    for (let ee of arr) {
        arrCpy.push(ee);
    }
    arrCpy.push(el);
    return arrCpy;
}

function tag(sources, newSource, target) { return sources.includes(target.name) ? target.name : target.fn(append(sources, newSource.name)) };
function label(source) { return (<b style={{ color: '#B690F4' }}>{source.name}</b>) };



// for individual note tooltips -> just highlight the note over a piano??? i.e. only image, no text???


// /* MODES */

const mode = { fn: function (sources) { return <MyToolTip title={<div>A mode is a scale whose notes start and end at some point in the major scale. Each mode has its own set of chords which in theory can be used in any other mode</div>}>{label(this)}</MyToolTip> }, name: "mode" }
// const ionian = { fn: function (sources) { return <MyToolTip title={<div>The Ionian {tag(sources, this, mode)}, also called the "{tag(sources, this, major)} {tag(sources, this, scale)}", is a major mode whose notes can be easily found by starting and ending at the "C" key on a piano and playing all the white keys, i.e. C-D-E-F-G-A-B. It is the most common scale used in popular Western music and many songs only use chords which are diatonic to this mode</div>}>{label(this)}</MyToolTip> }, name: "Ionian" }
// const dorian = { fn: function (sources) { return <MyToolTip title={<div>The Dorian {tag(sources, this, mode)} is a {tag(sources, this, minor)} mode whose {tag(sources, this, root)} is the second note of the {tag(sources, this, major)} {tag(sources, this, scale)}. It is equivalent to the conventional minor scale with a {tag(sources, this, majorSixth)} instead of a {tag(sources, this, minorSixth)}. e.g. D-E-F-G-A-B-C-D. It is a common scale in popular Western music as well as Medieval and Renaissance music</div>}>{label(this)}</MyToolTip> }, name: "Dorian" }
// const phrygian = { fn: function (sources) { return <MyToolTip title={<div>The Phrygian {tag(sources, this, mode)} is a {tag(sources, this, minor)} mode whose {tag(sources, this, root)} is the third note of the {tag(sources, this, major)} {tag(sources, this, scale)}. e.g. E-F-G-A-B-C-D. It is equivalent to the conventional minor scale with a {tag(sources, this, semitone)} instead of a {tag(sources, this, wholeTone)}. It is often used in Eastern music and when Western artists want to sound edgy</div>}>{label(this)}</MyToolTip> }, name: "Phrygian" }
// const lydian = { fn: function (sources) { return <MyToolTip title={<div>The Lydian {tag(sources, this, mode)} is a {tag(sources, this, minor)} mode whose {tag(sources, this, root)} is the fourth note of the {tag(sources, this, major)} {tag(sources, this, scale)}. e.g. F-G-A-B-C-D-E. It is equivalent to the conventional major scale with a {tag(sources, this, tritone)} instead of a {perfectFourth}. It is a less common scale that is very open and non-controversial</div>}>{label(this)}</MyToolTip> }, name: "Lydian" }
// const mixolydian = { fn: function (sources) { return <MyToolTip title={<div>The Mixolydian {tag(sources, this, mode)} is a {tag(sources, this, major)} mode whose {tag(sources, this, root)} is the fifth note of the {tag(sources, this, major)} {tag(sources, this, scale)}. e.g. G-A-B-C-D-E-F. It is equivalent to the conventional major scale with a {tag(sources, this, minorSeventh)} instead of a {tag(sources, this, seventh)}. It is a common scale used in blues and pop</div>}>{label(this)}</MyToolTip> }, name: "Mixolydian" }
// const aeolian = { fn: function (sources) { return <MyToolTip title={<div>The Aeolian {tag(sources, this, mode)}, also called the "{tag(sources, this, minor)} {tag(sources, this, scale)}", is a minor mode whose {tag(sources, this, root)} is the sixth note of the {tag(sources, this, major)} {tag(sources, this, scale)}. e.g. A-B-C-D-E-F-G. It is the second-most common scale used in popular Western music and many songs only use chords which are diatonic to this mode</div>}>{label(this)}</MyToolTip> }, name: "Aeolian" }
// const locrian = { fn: function (sources) { return <MyToolTip title={<div>The Locrian {tag(sources, this, mode)} is a {tag(sources, this, minor)} mode whose {tag(sources, this, root)} is the seventh note of the {tag(sources, this, major)} {tag(sources, this, scale)}. e.g. B-C-D-E-F-G-A. It is equivalent to the conventional minor scale with a {tag(sources, this, semitone)} instead of a {tag(sources, this, wholeTone)} and a {tag(sources, this, tritone)} instead of a {tag(sources, this, perfectFifth)}. It is an uncommon scale rarely used in Western music because the absence of the {tag(sources, this, perfectFifth)} causes this scale to lack stability</div>}>{label(this)}</MyToolTip> }, name: "Locrian" }

// // /* INTERVALS */

// const wholeTone = { fn: function (sources) { return <MyToolTip title={<div>A whole tone is a versatile {tag(sources, this, interval)} that is equal to two {tag(sources, this, interval)}s, e.g. C-D. It is found in both {tag(sources, this, major)} and {tag(sources, this, minor)} {tag(sources, this, key)}s and works with almost all {tag(sources, this, chord)}s</div>}>{label(this)}</MyToolTip> }, name: "tone" }
// const semitone = { fn: function (sources) { return <MyToolTip title={<div>A semitone is the {tag(sources, this, interval)} of distance 1, which can be any two notes that are next to each other e.g. C-Db. It is relatively uncommon but does appear in the {tag(sources, this, phrygian)} and {tag(sources, this, locrian)} {tag(sources, this, mode)}s, as well as {tag(sources, this, altered)} {tag(sources, this, chord)}s and {tag(sources, this, scale)}s</div>}>{label(this)}</MyToolTip> }, name: "semitone" }
// const ninth = { fn: function (sources) { return <MyToolTip title={<div>A ninth is a {tag(sources, this, wholeTone)} raised by an {tag(sources, this, octave)}. It can be altered {tag(sources, this, flat)} or {tag(sources, this, sharp)} becoming b9 or #9. Ninths are the sum of four {tag(sources, this, third)}s and often show up in {tag(sources, this, voicing)}s of {tag(sources, this, chord)}s which are built in thirds</div>}>{label(this)}</MyToolTip> }, name: "ninth" }

// const minorThird = { fn: function (sources) { return <MyToolTip title={<div>A {tag(sources, this, minor)} third is the defining {tag(sources, this, interval)} of "minor-ness" and is equal to three {tag(sources, this, semitone)}s, e.g. C-Eb. It is found in minor {tag(sources, this, key)}s and {tag(sources, this, chord)}s (and {tag(sources, this, diminished)} chords)</div>}>{label(this)}</MyToolTip> }, name: "minor third" }
// const majorThird = { fn: function (sources) { return <MyToolTip title={<div>A {tag(sources, this, major)} third is the defining {tag(sources, this, interval)} of "major-ness" and is equal to four {tag(sources, this, semitone)}s, e.g. C-E. It is found in major {tag(sources, this, key)}s and {tag(sources, this, chord)}s (and {tag(sources, this, dominant)} chords)</div>}>{label(this)}</MyToolTip> }, name: "major third" }
// const third = { fn: function (sources) { return <MyToolTip title={<div>A third can be either {tag(sources, this, major)} or {tag(sources, this, minor)} and is equal to either three or four {tag(sources, this, semitone)}s. Thirds are conventionally the building blocks of {tag(sources, this, chord)}s and define whether a chord, {tag(sources, this, key)}, or {tag(sources, this, scale)} is major or minor</div>}>{label(this)}</MyToolTip> }, name: "third" }

// const perfectFourth = { fn: function (sources) { return <MyToolTip title={<div>A perfect fourth is a stable {tag(sources, this, interval)} equal to five {tag(sources, this, semitone)}s, e.g. C-F. It reflects the {tag(sources, this, perfectFifth)}, as ascending a fifth then a fourth, or vice versa, takes you back to the note you started with. The fourth is found in both {tag(sources, this, major)} and {tag(sources, this, minor)} {tag(sources, this, key)}s but it is less common in {tag(sources, this, chord)}s</div>}>{label(this)}</MyToolTip> }, name: "fourth" }
// const eleventh = { fn: function (sources) { return <MyToolTip title={<div>An eleventh is a {tag(sources, this, perfectFourth)} raised by an {tag(sources, this, octave)}. It can be altered {tag(sources, this, sharp)} becoming #11. Elevenths are the sum of five {tag(sources, this, third)}s and sometimes show up in {tag(sources, this, voicing)}s for {tag(sources, this, chord)}s which are built in thirds</div>}>{label(this)}</MyToolTip> }, name: "eleventh" }

// const tritone = { fn: function (sources) { return <MyToolTip title={<div>A tritone is a symmetrical, unstable {tag(sources, this, interval)} which adds {tag(sources, this, harmonicTension)}, equal to six {tag(sources, this, semitone)}s, e.g. C-F#. Ascending two tritones takes you back to the note you started with. The tritone is found in neither {tag(sources, this, major)} nor {tag(sources, this, minor)} keys and is only found in {tag(sources, this, diminished)} chords and the {tag(sources, this, locrian)} {tag(sources, this, mode)}</div>}>{label(this)}</MyToolTip> }, name: "tritone" }

// const perfectFifth = { fn: function (sources) { return <MyToolTip title={<div>A perfect fifth is a strong, stabilizing {tag(sources, this, interval)} equal to seven {tag(sources, this, semitone)}s, e.g. C-G. It is found in both {tag(sources, this, major)} and {tag(sources, this, minor)} {tag(sources, this, key)}s and most {tag(sources, this, chord)}s</div>}>{label(this)}</MyToolTip> }, name: "fifth" }
// const fifth = { fn: function (sources) { return <MyToolTip title={<div>A fifth can be {tag(sources, this, natural)}, {tag(sources, this, sharp)}, or {tag(sources, this, flat)} and is equal to either 6, 7, or 8 {tag(sources, this, semitone)}s. The natural or {tag(sources, this, perfectFifth)}, equal to 7 semitones, is by far the most common of these. Fifths are the sum of two {tag(sources, this, third)}s and are {tag(sources, this, chordTone)}s in chords built in thirds</div>}>{label(this)}</MyToolTip> }, name: "fifth" }

// const majorSixth = { fn: function (sources) { return <MyToolTip title={<div>A major sixth is an open and colourful {tag(sources, this, interval)} found in {tag(sources, this, major)} {tag(sources, this, key)}s, equal to 9 {tag(sources, this, semitone)}s, e.g. C-A.. The sixth of a {tag(sources, this, chord)} (e.g. an A over a chord whose {tag(sources, this, root)} is C) is usually available to be played. Tip: the sixth is linked with the {tag(sources, this, perfectFifth)}. In melodies and {tag(sources, this, voicing)}s, try using one after the other or replacing one with the other</div>}>{label(this)}</MyToolTip> }, name: "major sixth" }
// const minorSixth = { fn: function (sources) { return <MyToolTip title={<div>A minor sixth is a harsh but colourful {tag(sources, this, interval)} equal to eight {tag(sources, this, semitone)}s, e.g. C-Ab. It is found in all {tag(sources, this, minor)} {tag(sources, this, key)}s except the {tag(sources, this, dorian)} {tag(sources, this, mode)} but is not typically played in {tag(sources, this, chord)}s unless it replaces the {tag(sources, this, fifth)} as they clash with each other</div>}>{label(this)}</MyToolTip> }, name: "minor sixth" }
// const sixth = { fn: function (sources) { return <MyToolTip title={<div>See {tag(sources, this, thirteenth)}, since sixths and {tag(sources, this, thirteenth)}s are the same {tag(sources, this, note)} one {tag(sources, this, octave)} apart</div>}>{label(this)}</MyToolTip> }, name: "sixth" }
// const thirteenth = { fn: function (sources) { return <MyToolTip title={<div>A thirteenth is a {tag(sources, this, majorSixth)} raised by an {tag(sources, this, octave)}. It can be altered {tag(sources, this, flat)} becoming b13. Thirteenths are the sum of six {tag(sources, this, third)}s and often show up in {tag(sources, this, voicing)}s for {tag(sources, this, chord)}s which are built in thirds</div>}>{label(this)}</MyToolTip> }, name: "thirteenth" }

// const minorSeventh = { fn: function (sources) { return <MyToolTip title={<div>A minor seventh is an {tag(sources, this, interval)} frequently used in melodies and voicings which is equal to ten {tag(sources, this, semitone)}s, e.g. C-Bb. It is found in {tag(sources, this, minor)} {tag(sources, this, key)}s and both {tag(sources, this, minor)} and {tag(sources, this, dominant)} {tag(sources, this, chord)}s</div>}>{label(this)}</MyToolTip> }, name: "minor seventh" }
// const majorSeventh = { fn: function (sources) { return <MyToolTip title={<div>A major seventh is an {tag(sources, this, interval)} frequently used in melodies and voicings which is equal to eleven {tag(sources, this, semitone)}s, e.g. C-B. It is found in most {tag(sources, this, major)} {tag(sources, this, scale)}s and is a {tag(sources, this, chordTone)} in {tag(sources, this, majorChord)}s</div>}>{label(this)}</MyToolTip> }, name: "major seventh" }
// const seventh = { fn: function (sources) { return <MyToolTip title={<div>A seventh can be either {tag(sources, this, major)} or {tag(sources, this, minor)} and is equal to either ten or eleven {tag(sources, this, semitone)}s. Sevenths are the sum of three {tag(sources, this, third)}s and are {tag(sources, this, chordTone)}s in most {tag(sources, this, chord)}s which are built in thirds, namely {tag(sources, this, seventhChord)}s</div>}>{label(this)}</MyToolTip> }, name: "seventh" }
// const octave = { fn: function (sources) { return <MyToolTip title={<div>An octave is an {tag(sources, this, interval)} equal to twelve {tag(sources, this, semitone)}s. Ascending an octave takes you back to the {tag(sources, this, note)} you started with</div>}>{label(this)}</MyToolTip> }, name: "octave" }


// // /* CHORD TYPES */

// {/* <div>Diminished {tt.tooltips.tag([], {name: "diminished"}, tt.tooltips.chord)}s consist of a {tt.tooltips.tag([], {name: "diminished"}, tt.tooltips.minorThird)}, a {tt.tooltips.tag([], {name: "diminished"}, tt.tooltips.tritone)}, and a {tt.tooltips.tag([], {name: "diminished"}, tt.tooltips.majorSixth)}. Diminished chords usually have a {targetChorsd} because of the {tag(sources, this, harmonicTension)} within two tritones between their {tag(sources, this, root)} and tritone, and their minor third and major sixth. Chords resolving diminished chords are most often a {tag(sources, this, semitone)} above (e.g. B7 - C) though alternate resolutions are theoretically possible and very common</div> */}
// const diminished = { fn: function (sources) { return <MyToolTip title={<div>Diminished {tag(sources, this, chord)}s consist of a {tag(sources, this, minorThird)}, a {tag(sources, this, tritone)}, and a {tag(sources, this, majorSixth)}. Diminished chords usually have a {tag(sources, this, chordResolution)} because of the {tag(sources, this, harmonicTension)} of two tritones between their {tag(sources, this, root)} and tritone, and their minor third and major sixth. Chords following diminished chords are most often a semitone above (e.g. B7 - C) though alternate resolutions are theoretically possible and very common</div>}>{label(this)}</MyToolTip> }, name: "diminished" }
// const halfDiminished = { fn: function (sources) { return <MyToolTip title={<div>Half-diminished {tag(sources, this, chord)}s consist of a {tag(sources, this, minorThird)}, a {tag(sources, this, tritone)}, and a {tag(sources, this, minorSeventh)}. Half-diminished chords are tense and unstable because of the tritone between their root and tritone, so they tend to be resolved. Half-diminished chords are often the 2 of a minor 2-5, and can also resolve down a tone</div>}>{label(this)}</MyToolTip> }, name: "half-diminished" }
const dominant = { fn: function (sources) { return <MyToolTip title={<div>Dominant chords are tense and unstable, so they tend to be followed by a resolved/relaxed chord. They come in many shapes and sizes</div>}>{label(this)}</MyToolTip> }, name: "dominant" }
// const majorChord = { fn: function (sources) { return <MyToolTip title={<div>{tag(sources, this, major)} {tag(sources, this, chord)}s consist of a {tag(sources, this, majorThird)}, a {tag(sources, this, perfectFifth)}, and a {tag(sources, this, majorSeventh)}. Major chords need no resolution because of the absence of a {tag(sources, this, tritone)} between any of its notes. Major chords can sound happy, relaxed, or melancholy</div>}>{label(this)}</MyToolTip> }, name: "major chord" }
// const minorChord = { fn: function (sources) { return <MyToolTip title={<div>{tag(sources, this, minor)} {tag(sources, this, chord)}s consist of a {tag(sources, this, minorThird)}, a {tag(sources, this, perfectFifth)}, and a {tag(sources, this, minorSeventh)}. Minor chords need no resolution because of the absence of a {tag(sources, this, tritone)} between any of its notes. Minor chords can sound sad, dark, or angry</div>}>{label(this)}</MyToolTip> }, name: "minor chord" }
// const seventhChord = { fn: function (sources) { return <MyToolTip title={<div>Seventh {tag(sources, this, chord)}s are built in {tag(sources, this, third)}s and consist of a third, a {tag(sources, this, fifth)}, and a {tag(sources, this, seventh)}. {tag(sources, this, tension)}s may be used to add colours to seventh chords. Omitting the seventh of a seventh chord results in a {tag(sources, this, triad)}</div>}>{label(this)}</MyToolTip> }, name: "seventh chord" }
// const triad = { fn: function (sources) { return <MyToolTip title={<div>Triads are {tag(sources, this, chord)}s built in {tag(sources, this, third)}s which consist of a third and a {tag(sources, this, fifth)}. Triads are common in pop music because of their simplicity and common in Classical music because of their boldness</div>}>{label(this)}</MyToolTip> }, name: "minor chord" }


/* KEY TERMS */

// const note = { fn: function (sources) { return <MyToolTip title={<div>A note is the simplest unit of music. An example of a note is one key on a piano being played once. Each note has a letter name (e.g. C, A) and may be {tag(sources, this, sharp)} or {tag(sources, this, flat)} (e.g. Db, F#)</div>}>{label(this)}</MyToolTip> }, name: "note" }
// const chord = { fn: function (sources) { return <MyToolTip title={<div>A chord is a set of {tag(sources, this, note)}s played at once. The foundational note is called the {tag(sources, this, root)}. Additional notes are typically added in {tag(sources, this, interval)}s of {tag(sources, this, third)}s. These thirds typically sum to intervals such as the {tag(sources, this, perfectFifth)}, {tag(sources, this, minorSeventh)}, {tag(sources, this, majorSeventh)}, and ultimately wrap back around to the start of the scale to be the {tag(sources, this, ninth)}, {tag(sources, this, eleventh)}, and {tag(sources, this, thirteenth)}</div>}>{label(this)}</MyToolTip> }, name: "chord" }
// const root = { fn: function (sources) { return <MyToolTip title={<div>A root of a {tag(sources, this, key)} is the first {tag(sources, this, note)} of that key. A root of a {tag(sources, this, chord)} is the foundation of that chord and is typically played in the bass; it may or may not be played in the accompaniment</div>}>{label(this)}</MyToolTip> }, name: "root" }
// const interval = { fn: function (sources) { return <MyToolTip title={<div>An interval is the distance between two {tag(sources, this, note)}s, e.g. {tag(sources, this, wholeTone)}, {tag(sources, this, perfectFifth)}, {tag(sources, this, tritone)}. Playing an interval "of a {tag(sources, this, chord)}" or "of a {tag(sources, this, key)}" means playing the note and interval above the {tag(sources, this, root)} of that chord or key (e.g. the {tag(sources, this, third)} of C {tag(sources, this, major)} is E because E is a third above C, which is the root of the chord/key)</div>}>{label(this)}</MyToolTip> }, name: "interval" }
// const key = { fn: function (sources) { return <MyToolTip title={<div>A key defines the harmonic template for a song and therefore is typically chosen before the {tag(sources, this, chord)}s and melody are written. A key is comprised of a {tag(sources, this, root)} note and a {tag(sources, this, scale)}, e.g. C major.</div>}>{label(this)}</MyToolTip> }, name: "key" }
// const scale = { fn: function (sources) { return <MyToolTip title={<div>A scale is a set of 5-8 notes which can be combined to form harmonies and melodies. Each {tag(sources, this, chord)} has at least one corresponding scale e.g. the chord A minor can use the {tag(sources, this, aeolian)} or {tag(sources, this, dorian)} scale</div>}>{label(this)}</MyToolTip> }, name: "scale" }
// const diatonic = { fn: function (sources) { return <MyToolTip title={<div>A {tag(sources, this, note)} is "diatonic to a {tag(sources, this, scale)}" if it belongs to that scale. A {tag(sources, this, chord)} is "diatonic to a scale" if each of its notes belong to that scale</div>}>{label(this)}</MyToolTip> }, name: "diatonic" }
// const natural = { fn: function (sources) { return <MyToolTip title={<div>A {tag(sources, this, note)} is "natural" if it belongs to a {tag(sources, this, scale)} or {tag(sources, this, chord)}. When a note is called "natural" in absence of the context of a scale or chord, it refers to a white note on a piano. The opposite of natural is {tag(sources, this, altered)}.</div>}>{label(this)}</MyToolTip> }, name: "natural" }
// const altered = { fn: function (sources) { return <MyToolTip title={<div>A {tag(sources, this, note)} is "altered" if it does not belong to a {tag(sources, this, scale)} or {tag(sources, this, chord)}. Altered scales and chords use most or all available altered {tag(sources, this, tension)}s over a {tag(sources, this, dominant)} chord. The opposite of altered is {tag(sources, this, natural)}</div>}>{label(this)}</MyToolTip> }, name: "altered" }
// const major = { fn: function (sources) { return <MyToolTip title={<div>A {tag(sources, this, chord)}, {tag(sources, this, scale)} or {tag(sources, this, key)} is "major" if it contains a {tag(sources, this, majorThird)} e.g. the {tag(sources, this, root)} is C and an E is used. An {tag(sources, this, interval)} is major if it is commonly found in major scales. Major-ness is often associated with openness and positive emotions</div>}>{label(this)}</MyToolTip> }, name: "major" }
// const minor = { fn: function (sources) { return <MyToolTip title={<div>A {tag(sources, this, chord)}, {tag(sources, this, scale)} or {tag(sources, this, key)} is "minor" if it contains a {tag(sources, this, minorThird)} e.g. the {tag(sources, this, root)} is C and an Eb is used. An {tag(sources, this, interval)} is minor if it is commonly found in minor scales. Minor-ness is often associated with darker and deeper negative emotions</div>}>{label(this)}</MyToolTip> }, name: "minor" }
// const chordTone = { fn: function (sources) { return <MyToolTip title={<div>A {tag(sources, this, chord)} tone is a {tag(sources, this, note)} belonging to a chord which is fundamental to the character of the chord. As well as the {tag(sources, this, root)}, they are typically {tag(sources, this, third)}s, {tag(sources, this, fifth)}s, and {tag(sources, this, seventh)}s, although sometimes can be {tag(sources, this, perfectFourth)}s or {tag(sources, this, majorSixth)}s</div>}>{label(this)}</MyToolTip> }, name: "flat" }
// const tension = { fn: function (sources) { return <MyToolTip title={<div>A tension is a {tag(sources, this, note)} belonging to a {tag(sources, this, chord)} which is not fundamental to the character of the chord as {tag(sources, this, chordTone)}s are, but adds color and depth. Tensions can be {tag(sources, this, ninth)}s, {tag(sources, this, eleventh)}s, and {tag(sources, this, thirteenth)}s, and can be either {tag(sources, this, natural)} or {tag(sources, this, altered)}. Natural tensions include 9, 11, and 13, while altered tensions include b9, #9, #11, and b13</div>}>{label(this)}</MyToolTip> }, name: "tension" }
const openVoicing = { fn: function (sources) { return <MyToolTip title={<div>A voicing is open if it has large gaps between its notes which leaves room for melodies to be played with notes in the same range</div>}>{label(this)}</MyToolTip> }, name: "open" }
const closedVoicing = { fn: function (sources) { return <MyToolTip title={<div>A voicing is closed if its notes are close together. This may interfere with melodies which are played with notes in the same range</div>}>{label(this)}</MyToolTip> }, name: "closed" }
// const quality = { fn: function (sources) { return <MyToolTip title={<div>A {tag(sources, this, chord)}'s' quality defines its {tag(sources, this, chordTone)}s, i.e. the fundamental character of that chord. Examples are {tag(sources, this, majorChord)}, {tag(sources, this, minorChord)}, {tag(sources, this, dominant)}, {tag(sources, this, diminished)}, and {halfDiminished}</div>}>{label(this)}</MyToolTip> }, name: "quality" }
// const harmonicTension = { fn: function (sources) { return <MyToolTip title={<div>Harmonic tension is a property of chords such as {tag(sources, this, dominant)} and {tag(sources, this, diminished)} which refers to a feeling that they need to be resolved. It is often caused by a {tag(sources, this, tritone)} between a chord's notes. The tritone creates tension because of its symmetry, which has a disorienting effect on one's ear</div>}>{label(this)}</MyToolTip> }, name: "harmonic tension" }
// const chordResolution = { fn: function (sources) { return <MyToolTip title={<div>Chord resolution is the process of following a chord with {tag(sources, this, harmonicTension)} such as {tag(sources, this, dominant)} or {tag(sources, this, diminished)} with a chord that does not such as {tag(sources, this, majorChord)} or {tag(sources, this, minorChord)}. Resolving "down a {tag(sources, this, fifth)}" means that the root of the chord being resolved to (i.e. the chord lacking tension) is a {tag(sources, this, perfectFifth)} below the chord being resolved (i.e. the chord possessing tension)</div>}>{label(this)}</MyToolTip> }, name: "resolution" }
// const relativeChord = { fn: function (sources) { return <MyToolTip title={<div>When a {tag(sources, this, chord)} is described as a relative number with to another chord, it means the chord's {tag(sources, this, root)} is the specified number's corresponding {tag(sources, this, interval)} above the other chord. For example, a "relative 5" of C would be the note which is a {tag(sources, this, perfectFifth)} above C, which is G. A relative 2 would be a {tag(sources, this, wholeTone)} above, a relative 3 would be a {tag(sources, this, third)} above, etc.</div>}>{label(this)}</MyToolTip> }, name: "relative" }
// const primaryDominant = { fn: function (sources) { return <MyToolTip title={<div>The primary dominant chord is a {tag(sources, this, dominant)} {tag(sources, this, chord)} whose {tag(sources, this, root)} is the {tag(sources, this, perfectFifth)} of the {tag(sources, this, key)}. In {tag(sources, this, major)} keys, the primary dominant typically uses {tag(sources, this, natural)} {tag(sources, this, tension)}s. In {tag(sources, this, minor)} keys, the primary dominant typically uses {tag(sources, this, altered)} tensions</div>}>{label(this)}</MyToolTip> }, name: "primary dominant" }


// const sharp = { fn: function (sources) { return <MyToolTip title={<div>A {tag(sources, this, note)} is sharp (#) with respect to another note if it is a {tag(sources, this, semitone)} above that note (e.g. C -> C#). Notes of a {tag(sources, this, chord)} or {tag(sources, this, scale)} can be replaced by the note a semitone above them in order to add spice and color</div>}>{label(this)}</MyToolTip> }, name: "sharp" }
// const flat = { fn: function (sources) { return <MyToolTip title={<div>A {tag(sources, this, note)} is flat (b) with respect to another note if it is a {tag(sources, this, semitone)} below that note (e.g. D -> Db). Notes of a {tag(sources, this, chord)} or {tag(sources, this, scale)} can be replaced by the note a semitone below them in order to add a blues-y feel</div>}>{label(this)}</MyToolTip> }, name: "flat" }
const voicing = { fn: function (sources) { return <MyToolTip title={<div>A voicing is a set of notes played for a chord which can vary in complexity</div>}>{label(this)}</MyToolTip> }, name: "voicing" }



const tooltips = {
    tag: tag,
    // chord: chord,
    // chordTone: chordTone,
    // chordResolution: chordResolution,
    closedVoicing: closedVoicing,
    // diatonic: diatonic,
    dominant: dominant,
    // harmonicTension: harmonicTension,
    // key: key,
    // major: major,
    // majorChord: majorChord,
    // majorSixth: majorSixth,
    // minor: minor,
    // minorChord: minorChord,
    // minorThird: minorThird,
    mode: mode,
    // natural: natural,
    openVoicing: openVoicing,
    // primaryDominant: primaryDominant,
    // quality: quality,
    // relativeChord: relativeChord,
    // root: root,
    // semitone: semitone,
    // tension: tension,
    // tritone: tritone,
    voicing: voicing,

}

function link(url, name){
    return <a className="normal" style={{textDecoration: "none"}} target="_blank" href={url}><MyToolTip title={<div>Click to learn!</div>}><b>{name}</b></MyToolTip></a>;
}

const links = {
    voicing: link("https://en.wikipedia.org/wiki/Voicing_(music)", "voicing"),

}

const tt = {
    tooltips: tooltips,
    links: links,
    fn: MyToolTip,
}

export default tt;