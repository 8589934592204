import React, {Component} from 'react';

import MidiWriter from 'midi-writer-js'; 
// NOTE: consider changing to ToneJS midi file writer if this gets annoying
// https://github.com/Tonejs/Midi/blob/master/README.md
import WebMidi from 'webmidi';

import {ClipLoader} from 'react-spinners';

import Slider, {Range} from 'rc-slider';
import 'rc-slider/assets/index.css';

import Switch from 'react-switch';

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import './DropDown.css';

import './icons.css'

import tt from './Tooltips';

import * as Tone from 'tone';


import {IoInformationCircleOutline, IoVolumeMedium, IoAddCircleOutline, IoPauseCircleOutline, IoPlayCircleOutline, IoHelpCircleOutline, IoPlaySharp, IoPauseSharp, IoDownloadOutline, IoReloadCircleOutline, IoRefresh, IoShuffle, IoArrowDown, IoArrowUp, IoTrendingUpSharp, IoVolumeHigh, IoVolumeMute, IoReturnUpForward} from "react-icons/io5";

import Piano from './Piano';
import { chordTranspose } from 'd3-chord';
import { count } from 'd3-array';


class Amadeus extends Component{

    constructor(props){
        super(props);

        let initDrumComplexity = 2;

        this.state = {
            key: "",
            keyIsRandom: false,
            
            chords: [],
            bassNotes: [],
            harmonyNotes: [],
            activeHarmonyNotes: [],
            scaleNotes: [],

            highMediumLowSettings: 'medium',
            activeMidiInput: 'none',
            settings: {
                numberOfBars: '4',
                voicingComplexity: 2,
                closedOrOpen: "closed",
                passingChordFrequency: 0,

                majorTwoFive: false,
                minorTwoFive: false,
                diminished: false,
                modalInterchange: false,
                primaryDominant: true,
                secondaryDominant: false,
                substituteDominant: false,

                keyMajorMinor: 'random',
                keyValue: 'random',


            },

            dependentChords: {
            },
            modalInterchange: [],
            baseChords: [],

            mediumLevelComplexity: 1,
            chordSelectedIdx: -1,
            tempo: 120,

            leadSynth: null,


            midiInputs: ['none'],

            drumComplexity: initDrumComplexity,
            drumMuted: false,

            volume: 50,
            isVolumeVisible: false,

            noWrongNotesMode: true,
            melodyInputOctave: 4,
        }

        this.pressedNotes = [];
        this.lastScaleNoteMap = {};
        this.scaleNoteMap = {};
        this.lastScaleNoteMapForMIDIInput = {};
        this.scaleNoteMapForMIDIInput = {};

        this.startingOctave = 2;


        this.resolvesToIDs = [];


        this.majorKeyOptions = ['random', 'A', 'Bb', 'B', 'C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G', 'Ab'];
        this.minorKeyOptions = ['random', 'A', 'Bb', 'B', 'C', 'C#', 'D', 'Eb', 'E', 'F', 'F#', 'G', 'G#'];


        this.loading = true;

        // for json request
        this.requestCount = 0;


        // for time loop
        this.loop = new Tone.Loop().start(0);
        this.drumLoop = new Tone.Loop().start(0);
        this.qNumber = -1;
        this.sNumber = -1;

        this.notesInBar = 4;
        this.barsInLoop = 4;

        this.chordOnIdx = -1;

        this.handleBasicOrAdvanced = this.handleBasicOrAdvanced.bind(this);

        // set up input event handlers

        this.handleMajorTwoFives = this.handleMajorTwoFives.bind(this);
        this.handleMinorTwoFives = this.handleMinorTwoFives.bind(this);
        this.handleDiminished = this.handleDiminished.bind(this);
        this.handleModalInterchange = this.handleModalInterchange.bind(this);
        this.handleClosedOrOpen = this.handleClosedOrOpen.bind(this);
        this.handleSecondaryDominant = this.handleSecondaryDominant.bind(this);
        this.handleSubstituteDominant = this.handleSubstituteDominant.bind(this);

        // set colors

        this.color1 = '#8545ed';
        this.color2 = '#B690F4';
        this.activeColor = '#89BD9E';
        this.activeColorSlightlyDarker = '#498360';
        this.badColor = '#EA526F';

        this.tooltip = tt.fn;

        // init synths

        this.padSynth = new Tone.Sampler({
            urls: {
                C2: "C1.mp3",
                Bb3: "Bb2.mp3",
                F5: "F4.mp3",

            },
            baseUrl: process.env.PUBLIC_URL + '/assets/bass-synth/',
            
        }).toDestination();
        this.padSynth.volume.value = -14;

        let hp_filter = new Tone.Filter(2000, 'highpass', -96).toDestination();
        let lp_filter_pad = new Tone.Filter(2200, 'lowpass', -24).toDestination();
        let lp_filter_lead = new Tone.Filter(4000, 'lowpass', -12).toDestination();
        //let hp_filter = new Tone.Filter(2000, 'highpass', -96).toDestination();
        this.padSynth.connect(lp_filter_pad);
        //This.padSynth.connect(hp_filter);

 
        this.bassSynth = new Tone.Sampler({
            urls: {
                C2: "C1.mp3",
                Bb2: "Bb1.mp3",
            },
            baseUrl: process.env.PUBLIC_URL + '/assets/bass-synth/',
            
        }).toDestination();
        this.bassSynth.volume.value = -12;

        let lp_filter_bass = new Tone.Filter(1500, 'lowpass', -96).toDestination();
        this.bassSynth.connect(lp_filter_bass);


        this.leadSynth = new Tone.Sampler({
            urls: {
                Bb2: "Bb2.mp3",
                Gb3: "Gb3.mp3",
                Eb4: "Eb4.mp3",
                Eb5: "Eb5.mp3",
                B5: "B5.mp3",
            },
            baseUrl: process.env.PUBLIC_URL + '/assets/lead/',
            
        }).toDestination();
        this.leadSynth.volume.value = -12;


        // this.leadSynth = new Tone.PolySynth(Tone.AMSynth, {
        //             oscillator: {
        //                 type: 'sine',
        //                 spread: 10,
        //                 phase: 0,
        //                 partialCount: 2,
        //             },
        //             envelope: {
        //               attack: 0.1,
        //               sustain: 0,
        //               decay: 1,
        //               release: 0.1,
        //             },

        //             detune: 1200,
        //             portamento: 1000,
        //             volume: -6
        //         });


        // this.leadSynth.connect(lp_filter_lead);
        // this.leadSynth.connect(hp_filter);
        // let reverb = new Tone.Reverb(1.5).toDestination();
        // this.leadSynth.connect(reverb);

        
        // this.kickSynth = new Tone.MembraneSynth().toDestination();
        // this.hatSynth = new Tone.NoiseSynth().toDestination();



        this.drumPatterns = {
            kick: [
                [
                    [1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0],
                    [1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0],                    
                ],
                [
                    [1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0],
                    [1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 0],
                    [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0],
                    [1, 0, 0, 0, 0, 0, 0, 2, 1, 0, 1, 0, 0, 0, 0, 0],
                    [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0],
                    [1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0],                    
                ],
                [
                    [1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0],
                    [1, 0, 0, 0, 0, 0, 0, 2, 1, 0, 1, 0, 0, 0, 0, 0],
                    [1, 0, 0, 0, 0, 0, 0, 2, 1, 0, 1, 0, 0, 0, 0, 0],
                    [1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 0],
                    [1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 1],
                    [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0],
                    [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0],
                    [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0],
                    [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0],
                    [1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0],                    
                    [1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0],                    
                ],
                [
                    [1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0],
                    [1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 0],
                    [1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 0],
                    [1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 1],
                    [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0],
                    [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0],
                    [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0],
                    [1, 0, 0, 0, 0, 0, 0, 2, 1, 0, 1, 0, 0, 0, 0, 0],
                    [1, 0, 0, 0, 0, 0, 0, 2, 1, 0, 1, 0, 0, 0, 0, 0],
                    [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0],
                    [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0],
                    [1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0],                    
                    [1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0],                  
                ],
                [
                    [1, 0, 0, 0, 0, 1, 0, 1, 0, 1, 0, 1, 0, 0, 0, 0],
                    [1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 0],
                    [1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 0],
                    [1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 1],
                    [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0],
                    [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0],
                    [1, 0, 0, 0, 0, 0, 0, 2, 1, 0, 1, 0, 0, 0, 0, 0],
                    [1, 0, 0, 0, 0, 0, 0, 2, 1, 0, 1, 0, 0, 0, 0, 0],
                    [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0],
                    [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0],
                    [1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0],                    
                    [1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0],                    
                ],
            ],
            snare: [
                [
                    [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0],
                    [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 2, 2],
                    [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0],
                ],
                [
                    [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0],
                    [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 2, 2],
                    [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0],
                    [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0],
                    [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0],
                    [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0],
                ],
                [
                    [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0],
                    [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 2, 2],
                    [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0],
                    [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0],
                    [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0],
                ],
                [
                    [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0],
                    [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 2, 2],
                    [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0],
                    [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0],
                    [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0],
                ],
                [
                    [1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0],
                    [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0],
                    [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 2, 2],
                    [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0],
                    [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0],
                ],
            ],
            hat: [
                [
                    [0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0],
                    [0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0],
                    [0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0],
                    [1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0],
                    [1, 0, 2, 0, 1, 0, 2, 0, 1, 0, 2, 0, 1, 0, 2, 0],
                    // [1, 0, 2, 0, 1, 0, 2, 0, 1, 0, 2, 0, 1, 0, 2, 2],
                ],
                [
                    [0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0],
                    [0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0],
                    [0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0],
                    [0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0],
                    [1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0],
                    [1, 0, 2, 0, 1, 0, 2, 0, 1, 0, 2, 0, 1, 0, 2, 0],
                    // [1, 0, 2, 0, 1, 0, 2, 0, 1, 0, 2, 0, 1, 0, 2, 2],
                ],
                [
                    [0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0],
                    [0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0],
                    [1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0],
                    [1, 0, 2, 0, 1, 0, 2, 0, 1, 0, 2, 0, 1, 0, 2, 0],
                    [1, 0, 2, 0, 1, 0, 2, 0, 1, 0, 2, 0, 1, 0, 2, 2],
                ],
                [
                    [0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0],
                    [0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0],
                    [1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0],
                    [1, 0, 2, 0, 1, 0, 2, 0, 1, 0, 2, 0, 1, 0, 1, 0],
                    // [1, 0, 2, 0, 1, 0, 2, 0, 1, 0, 2, 0, 1, 0, 2, 2],
                ],
                [
                    [0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0],
                    [0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0],
                    [0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0],
                    [1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0],
                    [1, 0, 2, 0, 1, 0, 2, 0, 1, 0, 2, 0, 1, 0, 2, 0],
                    // [1, 0, 2, 0, 1, 0, 2, 0, 1, 0, 2, 0, 1, 0, 2, 2],
                    // [1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2],
                    // [1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2],
                    // [1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2],
                ],
            ]
        }

        let kickPatterns = this.drumPatterns.kick[initDrumComplexity];
        let snarePatterns = this.drumPatterns.snare[initDrumComplexity];
        let hatPatterns = this.drumPatterns.hat[initDrumComplexity];

        this.activeDrums = {
            kickPattern: kickPatterns[Math.floor(Math.random()*kickPatterns.length)],
            snarePattern: snarePatterns[Math.floor(Math.random()*snarePatterns.length)],
            hatPattern: hatPatterns[Math.floor(Math.random()*hatPatterns.length)],
        }

        this.refreshDrumsMIDITrack();


        this.kickPlayer = new Tone.Player(process.env.PUBLIC_URL + '/assets/drums/kick.wav').toDestination();
        this.kickPlayer.volume.value = -8;
        this.snarePlayer = new Tone.Player(process.env.PUBLIC_URL + '/assets/drums/snare.wav').toDestination();
        this.snarePlayer.volume.value = -8;
        this.hatPlayer = new Tone.Player(process.env.PUBLIC_URL + '/assets/drums/hat.wav').toDestination();
        this.hatPlayer.volume.value = -14;



        // set margins/sizes

        
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            this.isMobile = true;
        } else {
            this.isMobile = false;
        }


        if (this.isMobile){
            this.xlFontSize = 18;
            this.bigFontSize = 14;
            this.smallFontSize = 10;
            this.bigMarginSizeVertical = '3vh';
            this.mediumMarginSizeVertical = '1vh';
            this.smallMarginSizeVertical = '0.5vh';
            this.bigMarginSizeHorizontal = '1vw';
            this.xlMarginSizeHorizontal = '2vw';
            this.biggerMarginSizeVertical = '1vh';
            this.boxMargin = '1vw';
            this.smallMarginSizeHorizontal = '0.5vw';
        } else {
            this.xlFontSize = 30;
            this.bigFontSize = 20;
            this.smallFontSize = 15;
            this.bigMarginSizeVertical = '4vh';
            this.mediumMarginSizeVertical = '2vh';
            this.bigMarginSizeHorizontal = '2vw';
            this.xlMarginSizeHorizontal = '3vw';
            this.biggerMarginSizeVertical = '6vh';
            this.boxMargin = '2vw';
            this.smallMarginSizeVertical = '1vh';
            this.smallMarginSizeHorizontal = '1vw';
        }


        this.keys = [
            87, 
            69, 
            82, 
            84, 
            89, 
            85, 
            73, 
            79, 
            80, 
            219, 
            221, 
            220,

            65, 
            83, 
            68, 
            70, 
            71, 
            72, 
            74, 
            75, 
            76, 
            186, 
            222, 
            13, 
   
        ]


        this.keyMap = {
            'A': 65,
            'W': 87,
            'S': 83,
            'E': 69,
            'D': 68,
            'F': 70,
            'T': 84,
            'G': 71,
            'Y': 89,
            'H': 72,
            'U': 85,
            'J': 74,
            'K': 75,
            'O': 79,
            'L': 76,
            'P': 80,
            ';': 186,
            "'": 222,
            'Z': 90,
            'X': 88,
        }

        this.noteMap = {
            65: 'C4',
            87: 'Db4',
            83: 'D4',
            69: 'Eb4',
            68: 'E4',
            70: 'F4',
            84: 'Gb4',
            71: 'G4',
            89: 'Ab4',
            72: 'A4',
            85: 'Bb4',
            74: 'B4',
            75: 'C5',
            79: 'Db5',
            76: 'D5',
            80: 'Eb5',
            186: 'E5',
            222: 'F5',

            32: 'space',
        }

        this.isPlaying = false;

    }

    componentDidMount(){
        // json request for initial chords here

        this.initMidiControllerInput();


        Tone.loaded().then(() => {
            this.setState({leadSynth: this.leadSynth},
                () => this.fetchNewLoop(this.state.settings)
            );
            this.setAllVolumes(this.state.volume)
        });

        document.addEventListener("keydown", (event) => {

            if (event.keyCode === 32){
                this.handleKeyPress('space');
                return;
            } 

            if (!this.state.noWrongNotesMode){

                    let note = this.noteMap[event.keyCode];
                    if (!note){ // key pressed is not x,z, space, or any key related to keyboard!
                        return;
                    }

                    let newNote = note.slice(0, -1);
                    if (note.slice(-1) == '4'){
                        newNote += String(this.state.melodyInputOctave);
                    } else{
                        newNote += String(this.state.melodyInputOctave + 1);
                    }

                    this.handleKeyPress(newNote); 

            } else {
                // console.log('c1');
                if (!this.scaleNoteMap[event.keyCode]){
                    // console.log(this.scaleNoteMap);
                    // console.log('c2');
                    return;
                } else {
                // console.log('c3');

                    let note = this.scaleNoteMap[event.keyCode];
                    let noteWithoutOctave = note.substring(0, note.length-1);
                    let octave = note.substring(note.length-1);
                    let enharmonicFlat = this.enharmonicFlat(noteWithoutOctave);
                    this.handleKeyPress(enharmonicFlat + octave);
                }
            }
            
            this.forceUpdate();
        }, false);
        document.addEventListener("keyup", (event) => {

            if (event.keyCode === 32){
                this.handleKeyRelease('space');
                return;
            }

            if (!this.state.noWrongNotesMode){
                let note = this.noteMap[event.keyCode];
                if (!note){ // key pressed is not x,z, space, or any key related to keyboard!
                    return;
                }

                let newNote = note.slice(0, -1);
                if (note.slice(-1) == '4'){
                    newNote += String(this.state.melodyInputOctave);
                } else{
                    newNote += String(this.state.melodyInputOctave + 1);
                }

                this.handleKeyRelease(newNote); 

            } else {
                if (!this.scaleNoteMap[event.keyCode]){
                    return;
                } else {
                    let note = this.scaleNoteMap[event.keyCode];
                    let noteWithoutOctave = note.substring(0, note.length-1);
                    let octave = note.substring(note.length-1);
                    let enharmonicFlat = this.enharmonicFlat(noteWithoutOctave);
                    this.handleKeyRelease(enharmonicFlat + octave);
                }
            }
            
            this.forceUpdate();
        }, false);
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", (event) => {
            if (!this.state.noWrongNotesMode){
                this.handleKeyPress(this.noteMap[event.keyCode]); 

            } else {
                if (!this.scaleNoteMap[event.keyCode] && event.keyCode !== 32){
                    return;
                }
                if (event.keyCode === 32){
                    this.handleKeyPress('space');
                } else {
                    let note = this.scaleNoteMap[event.keyCode];
                    let noteWithoutOctave = note.substring(0, note.length-1);
                    let octave = note.substring(note.length-1);
                    let enharmonicFlat = this.enharmonicFlat(noteWithoutOctave);
                    this.handleKeyPress(enharmonicFlat + octave);
                }
            }
            
            this.forceUpdate();
        }, false);
        document.removeEventListener("keyup", (event) => {

            if (!this.state.noWrongNotesMode){
                this.handleKeyRelease(this.noteMap[event.keyCode]); 

            } else {
                if (!this.scaleNoteMap[event.keyCode] && event.keyCode !== 32){
                    return;
                }
                if (event.keyCode === 32){
                    this.handleKeyRelease('space');
                } else {
                    let note = this.scaleNoteMap[event.keyCode];
                    let noteWithoutOctave = note.substring(0, note.length-1);
                    let octave = note.substring(note.length-1);
                    let enharmonicFlat = this.enharmonicFlat(noteWithoutOctave);
                    this.handleKeyRelease(enharmonicFlat + octave);
                }
            }
            
            this.forceUpdate();
        }, false);
    }

    initMidiControllerInput(){
        let _this = this;
        WebMidi.enable(function(err){
            if (err){
                console.log("web midi could not be enabled");
                return;
            }

            // console.log(WebMidi.inputs);
            // console.log(WebMidi.outputs);

            // Reacting when a new device becomes available
            WebMidi.addListener("connected", function(e) {
                // console.log(e);

                // console.log(WebMidi.inputs);
                // console.log(WebMidi.outputs);
                let inputs = WebMidi.inputs;
                let names = inputs.map((input) => input._midiInput.name);
                names.unshift('none');
                if (!!e.port._midiInput){
                    _this.setState({midiInputs: names, activeMidiInput: e.port._midiInput.name});
                    _this.handleMidiInputDropdownChange(e.port._midiInput.name);    
                }
                
            });
            
            // Reacting when a device becomes unavailable
            WebMidi.addListener("disconnected", function(e) {
                // console.log(e);
                let inputs = WebMidi.inputs;
                let names = inputs.map((input) => input._midiInput.name);
                if (inputs.includes(_this.state.activeMidiInput)){
                    _this.setState({midiInputs: names});
                } else {
                    _this.setState({midiInputs: names, activeMidiInput: 'none'});
                }

            });


        });
    }

    handleMidiInputDropdownChange(newVal){
        let _this = this;
        if (newVal == 'none'){
            let inputs = WebMidi.inputs;
            inputs.forEach((input) => {
                input.removeListener();
            })
            return;
        }
        // Retrieve an input by name, id or index
        var input = WebMidi.getInputByName(newVal);

        // Listen for a 'note on' message on all channels
        input.addListener('noteon', "all",
            function (e) {
                console.log("Received 'noteon' message (" + e.note.name + e.note.octave + ").");
                

                if (!_this.state.noWrongNotesMode){

                    _this.handleKeyPress(_this.enharmonicFlat(e.note.name) + e.note.octave);

                } else {
                    // console.log('c1');
                    let note = _this.enharmonicFlat(e.note.name) + e.note.octave;
                    // console.log('note')
                    // console.log(note)
                    // console.log('_this.scaleNoteMapForMIDIInput')
                    // console.log(_this.scaleNoteMapForMIDIInput)
                    // console.log('_this.scaleNoteMapForMIDIInput[note]')
                    // console.log(_this.scaleNoteMapForMIDIInput[note])
                    if (!_this.scaleNoteMapForMIDIInput[note]){
                        // console.log(this.scaleNoteMap);
                        // console.log('c2');
                        return;
                    } else {
                    // console.log('c3');

                        _this.handleKeyPress(_this.scaleNoteMapForMIDIInput[note]);
                    }
                }
            }
        );

        // Listen for a 'note off' message on all channels
        input.addListener('noteoff', "all",
            function (e) {
                console.log("Received 'noteoff' message (" + e.note.name + e.note.octave + ").");

                if (!_this.state.noWrongNotesMode){
                    _this.handleKeyRelease(_this.enharmonicFlat(e.note.name) + e.note.octave);

    
                } else {
                    // console.log('c1');
                    let note = _this.enharmonicFlat(e.note.name) + e.note.octave;
                    if (!_this.scaleNoteMapForMIDIInput[note]){
                        // console.log(this.scaleNoteMap);
                        // console.log('c2');
                        return;
                    } else {
                    // console.log('c3');

                        _this.handleKeyRelease(_this.scaleNoteMapForMIDIInput[note]);
                    }
                }
            }
        );
    }

    enharmonicFlat(note){
        switch(note){
            case "C#": return "Db";
            case "D#": return "Eb";
            case "F#": return "Gb";
            case "G#": return "Ab";
            case "A#": return "Bb";
            default: return note;
            
        }
    }

    handleKeyClick(key, _this){
        _this.handleKeyPress(_this.noteMap[_this.keyMap[key]]);
    }

    handleKeyClickRelease(key, _this){
        _this.handleKeyRelease(_this.noteMap[_this.keyMap[key]]);
    }

    handleKeyPress(note){
        if (note == null || note == ""){return;}

        if (note == 'space'){

            if (!this.loading && this.state.chords.length > 0){
                if (this.isPlaying){
                    this.endTimeLoop();                    
                } else {
                    this.endTimeLoop();                    
                    this.startTimeLoop(); 
                }
                
            }
            return;

        }

        // console.log('this.pressedNotes')
        // console.log(this.pressedNotes)
        // console.log('note inside handle key press')
        // console.log(note)

        if (!this.pressedNotes.includes(note)){
            this.leadSynth.triggerAttack(note, "+0", 1);
            this.pressedNotes.push(note);
            this.forceUpdate();
        }

    }

    handleKeyRelease(note){
        if (note == null || note == ""){return;}

        this.leadSynth.triggerRelease(note);
        this.pressedNotes = this.pressedNotes.filter((item) => item != note);
        this.forceUpdate();

    }

    // used when clicking on chords
    applyHarmonyNotes(measureNumber, deltaFromMeasure){
        let activeHarmonyNotes = [];
        this.state.harmonyNotes.forEach((harmonyNote) => {
            if (measureNumber == harmonyNote.measureNumber && deltaFromMeasure == harmonyNote.deltaFromMeasure){
                this.padSynth.triggerAttack(harmonyNote.note, "+0", harmonyNote.velocity/100); // divide by 100 because of library weirdness
                activeHarmonyNotes.push(harmonyNote.note);
            }
        })
        if (activeHarmonyNotes.length > 0){
            this.setState({activeHarmonyNotes: activeHarmonyNotes});
        }
    }

    // used when clicking on chords
    applyBassNotes(measureNumber, deltaFromMeasure){
        this.state.bassNotes.forEach((bassNote) => {
            if (measureNumber == bassNote.measureNumber && deltaFromMeasure == bassNote.deltaFromMeasure){
                this.bassSynth.triggerAttack(bassNote.note, "+0", bassNote.velocity/100); // divide by 100 because of library weirdness
            }
        })
    }

    getStartingPoint = (note) => {
        return "+" + String(note.measureNumber) + ":" + String(note.deltaFromMeasure) + ":0";
    }

    getNextQNumber(q){
        return (q + 1) % (this.barsInLoop * 4);
    }

    getNextSNumber(s){
        return (s + 1) % 16;
    }

    isChordOnQ(q){
        for (let note of this.state.harmonyNotes){
            if (q == note.measureNumber*4 + note.deltaFromMeasure){
                return true;
            }
        }
        return false;
    }


    trimToSix(chord){
        let scaleMap = {
            'Major': 3,
            'Ionian': 3,
            'Dorian': 3,
            'Phrygian': 1,
            // 'Phrygian': [1, 5],
            'Lydian': 4,
            'LydianFlat7': 4,
            'Mixolydian': 3,
            'V7Minor': [2, 4],
            'Minor': 5,
            'Aeolian': 5,
            'Locrian': 5,
            'MelodicMinor': 3,
            'Altered': 5,
            'HalfWhole': [2, 5],
            'WholeHalf': [3, 5],
            'DiatonicDominantVofII': 3,
        };

        let toRemove = scaleMap[chord.scale];
        let scale = JSON.parse(JSON.stringify(chord.scaleNotes)); // deep copy

        // let newScale = scale;

        let newScale = [];

        for (let note of scale){
            note = note.note;
            note = note.replace("flat", "b");
            note = note.replace("sharp", "#");        
            newScale.push(note);
        }

        if (!toRemove){
            return newScale;
        } else if (typeof toRemove === 'number'){
            newScale.splice(toRemove, 1);
            return newScale;
        } else {
            for (let el of toRemove){
                newScale.splice(el, 1);
            }
            return newScale;
        }

    }

    createScaleNoteMap(scaleNotes, offset){
        let scaleNoteMap = {};
        let forMidi = {};
        let notearr = ['C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'B'];
        let octavearr = ['3','4','5','6'];
        let octave = this.startingOctave-1;

        const getFirstNoteAboveC = (notes) => {
            for (let note of notearr){
                if (notes.includes(note)){
                    return note;
                }
            }
        }

        let firstNoteAboveC = getFirstNoteAboveC(scaleNotes);

        // console.log('creating scaleNoteMap');
        // console.log(scaleNotes);

        for (let i = 0; i < notearr.length * octavearr.length; i++){


            let idx = (i + offset) % scaleNotes.length;
            let noteval = this.enharmonicFlat(scaleNotes[idx]);

            if (noteval === firstNoteAboveC){
                octave++;
            }

            if (i < this.keys.length){
                let newnoteval = noteval.concat(String(octave));
                let key = this.keys[i];
                scaleNoteMap[key] = newnoteval;
            }
    
            noteval = noteval.concat(String(octave-1));
            forMidi[notearr[i%12]+octavearr[Math.floor(i/12)]] = noteval;
        }

        return {scaleNoteMap: scaleNoteMap, scaleNoteMapForMIDIInput: forMidi};
    }

    getOffset(scaleNotes){
        for (let note of ['C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'B']){
            if (scaleNotes.includes(note)){
                return scaleNotes.indexOf(note);   
            }
        }
    }

    setKeyMapping(chordSelectedIdx){
        this.state.chords.forEach((chord) => {
            
            let startq = chord.measureNumber * 4 + chord.beatNumber;
            let endq = startq + chord.lengthInBeats;

            let chordOn = startq <= this.qNumber && this.qNumber < endq;

            let idx = this.state.chords.indexOf(chord);

            if (chordOn || chordSelectedIdx === idx){
                // console.log(startq, endq, this.qNumber);
                this.chordOnIdx = idx;
                // console.log(chord);
                let scaleNotes;
                if (chord.scaleNotes.length > 6){
                    scaleNotes = this.trimToSix(chord);
                }
                if (!scaleNotes){
                    console.log('chord error');
                    console.log(chord);
                    console.log(chord.scale);
                }
                // console.log(scaleNotes);

                let offset = this.getOffset(scaleNotes);

                // console.log('setting key mapping')

                this.lastScaleNoteMap = JSON.parse(JSON.stringify(this.scaleNoteMap));
                this.scaleNoteMap = this.createScaleNoteMap(scaleNotes, offset).scaleNoteMap;

                this.lastScaleNoteMapForMIDIInput = JSON.parse(JSON.stringify(this.scaleNoteMapForMIDIInput));
                this.scaleNoteMapForMIDIInput = this.createScaleNoteMap(scaleNotes, offset).scaleNoteMapForMIDIInput;

                // console.log(this.scaleNoteMap)

                this.clearBadLeadNotes(scaleNotes);
            }
        })
    }

    startTimeLoop(){

        this.setChordSelected(-1);

        this.isPlaying = true;

        Tone.start();

        let lengthMap = {
            1: '4n',
            2: '2n',
            3: '2n.',
            4: '1m'
        }

        let loopTime = "4n";

        this.loop.callback = (time) => {
            // console.log(time);
            this.qNumber = this.getNextQNumber(this.qNumber);

            // console.log('yessir')
            // console.log(this.qNumber);
            // console.log(this.isChordOnQ(this.qNumber));
            if (this.isChordOnQ(this.qNumber)){
                // console.log('hello')
                this.clearAllNotes();
                this.setKeyMapping();
            }
            let activeHarmonyNotes = [];
            this.state.harmonyNotes.forEach((note) => {
                if (this.qNumber == note.measureNumber*4 + note.deltaFromMeasure){
                    this.padSynth.triggerAttackRelease(note.note, lengthMap[note.length], time, note.velocity/100);
                    activeHarmonyNotes.push(note.note);
                }
            });
            if (activeHarmonyNotes.length > 0){
                this.setState({activeHarmonyNotes: activeHarmonyNotes});
            }
            this.state.bassNotes.forEach((note) => {
                if (this.qNumber == note.measureNumber*4 + note.deltaFromMeasure){
                    this.bassSynth.triggerAttackRelease(note.note, lengthMap[note.length], time, note.velocity/100);
                }
            });

        }
        this.loop.interval = loopTime;


        this.drumLoop.callback = (time) => {
            this.sNumber = this.getNextSNumber(this.sNumber);

            if (!this.state.drumMuted){
                if (this.activeDrums.kickPattern[this.sNumber] == 1){
                    this.kickPlayer.start(time);
                } else if (this.activeDrums.kickPattern[this.sNumber] == 2){
                    this.kickPlayer.start(time);
                } else {
                    this.kickPlayer.stop(time);
                }
                if (this.activeDrums.snarePattern[this.sNumber] == 1){
                    this.snarePlayer.start(time);
                } else if (this.activeDrums.snarePattern[this.sNumber] == 2){
                    this.snarePlayer.start(time);
                } else {
                    this.snarePlayer.stop(time);
                }
                if (this.activeDrums.hatPattern[this.sNumber] == 1){
                    this.hatPlayer.start(time);
                } else if (this.activeDrums.hatPattern[this.sNumber] == 2){
                    this.hatPlayer.start(time);
                } else {
                    this.hatPlayer.stop(time);
                }
            }
        }
        this.drumLoop.interval = "16n";

        Tone.Transport.start();

    }

    endTimeLoop(){
        this.clearAllNotes();
        Tone.Transport.stop();
        this.qNumber = -1;
        this.sNumber = -1;
        this.chordOnIdx = -1;

        this.isPlaying = false;
        this.setChordSelected(-1);
    }


    noteHasDifferentTriggerKey(note, prev, curr){
        function getKeyByValue(object, value) {
            return Object.keys(object).find(key => object[key] === value);
        }

        let key1 = getKeyByValue(prev, note);
        let key2 = getKeyByValue(curr, note);

        return key1 !== key2;
          
    }

    getTriggerKey(note, obj){
        function getKeyByValue(object, value) {
            return Object.keys(object).find(key => object[key] === value);
        }

        return getKeyByValue(obj, note);
    }

    clearBadLeadNotes(scaleNotes){
        let removePressedNotes = [];
        let newNotes = [];
        this.pressedNotes.forEach((note) => {
            let noteWithoutOctave = note.substring(0, note.length-1);
            if (!scaleNotes.includes(noteWithoutOctave) || this.noteHasDifferentTriggerKey(note, this.lastScaleNoteMap, this.scaleNoteMap)){
                removePressedNotes.push(note);
                newNotes.push(this.scaleNoteMap[this.getTriggerKey(note, this.lastScaleNoteMap)]);
            }
        });

        removePressedNotes.forEach((note) => {
            this.leadSynth.triggerRelease(note);
        })
        newNotes.forEach((note) => {
            this.pressedNotes.push(note);
            this.leadSynth.triggerAttack(note);
        })
        this.pressedNotes = this.pressedNotes.filter((note) => !removePressedNotes.includes(note));
    }

    clearLeadNotes(){
        this.pressedNotes.forEach((note) => {
            this.leadSynth.triggerRelease(note);
        });
    }

    clearAllNotes(){
        // console.log('clearing')
        this.state.harmonyNotes.forEach((note) => {
            this.padSynth.triggerRelease(note.note);
        });
        this.state.bassNotes.forEach((note) => {
            this.bassSynth.triggerRelease(note.note);
        });
        this.setState({activeHarmonyNotes: []});
        // ["C1", "D1"].forEach((note) => {
        //     this.drumSampler.triggerRelease(note);
        // })
    }

    setAllVolumes(value){
        this.padSynth.volume.value = -22 + value/15;
        this.bassSynth.volume.value = -20 + value/15;
        this.leadSynth.volume.value = -20 + value/15;
        this.kickPlayer.volume.value = -16 + value/15;
        this.snarePlayer.volume.value = -16 + value/15;
        this.hatPlayer.volume.value = -22 + value/15;
    }


    render(){

        return (
            <>
            <div className="App-header" style={{display: 'flex', justifyContent: 'space-between', 
                fontSize: 20, paddingLeft: 32, paddingRight: 32, alignItems: 'center', height: 70, 
                backgroundColor: '#21081D', borderBottom: '1px solid #B690F477'}}>
                <div className="neonText" >Harmonia</div>



                <div style={{display: 'flex', }}>
                <div style={{display: 'flex', paddingLeft: 35, paddingTop: 10, paddingBottom: 10}} onMouseEnter={() => this.setState({isVolumeVisible: true})} onMouseLeave={() => this.setState({isVolumeVisible: false})}>
                    {this.state.isVolumeVisible && <div style={{width: 120, marginTop: 6, marginRight: 20}}>
                        <Slider
                            min={0}
                            max={100}
                            step={1}
                            onChange={value => {
                                this.setState({volume: value});
                                if (value === 0){
                                    this.padSynth.volume.value = -1000;
                                    this.bassSynth.volume.value = -1000;
                                    this.leadSynth.volume.value = -1000;
                                    this.kickPlayer.volume.value = -1000;
                                    this.snarePlayer.volume.value = -1000;
                                    this.hatPlayer.volume.value = -1000;    
                                    return;
                                }
                                this.setAllVolumes(value)
                                
                            }}
                            value={this.state.volume}
                            handleStyle={{
                                backgroundColor: this.color1,
                                borderColor: this.color1,
                                height:20,
                                width:20,
                                borderRadius: 10,
                                marginTop: -6,
                                marginBottom: 3
                            }}
                            trackStyle={{
                                backgroundColor: this.color2,
                                height:8,
                                width:130,
                                borderRadius:5,
                            }}
                            railStyle={{
                                height:8,
                                borderRadius:5,
                            }}
                        />
                    </div>}
                    <div style={{cursor: 'pointer', display: 'flex', alignItems: 'center'}}>
                    <IoVolumeMedium size={30}/>
                    </div>
                </div>
                {/* <div style={{cursor: 'pointer', display: 'flex', alignItems: 'center'}}>
                    <IoInformationCircleOutline size={30}/>
                </div> */}

                </div>
            </div>


            <div className="App">
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',}}>
                {this.state.leadSynth ? <div 
                className="shadow fade-in"
                // className="neon" 
                style={{borderRadius: '24px'}}>
                    <Piano activeColorSlightlyDarker={this.activeColorSlightlyDarker} 
                        primaryScaleNotes={this.state.scaleNotes} 
                        secondaryScaleNotes={[]} 
                        synth={this.state.leadSynth} 
                        correctColor={this.color1} 
                        incorrectColor={this.badColor}
                        activeColor={this.activeColor} 
                        activeHarmonyNotes={this.state.activeHarmonyNotes}
                        isMobile={this.isMobile}
                        pressedNotes={this.pressedNotes}    
                        handleKeyClick={this.handleKeyClick}
                        handleKeyClickRelease={this.handleKeyClickRelease}
                        parent={this}
                        startingOctave={this.startingOctave}
                        pianoLoaded={this.forceUpdate}
                    />
                </div> : <div style={{height: '120px'}}></div>}

                {this.renderChordSymbols()}

                <div style={{display: 'flex', flexDirection: 'row', marginTop: this.biggerMarginSizeVertical}}>

                    {this.renderComplexitySettings()}
                    {this.renderMiddleColumn()}
                    {this.renderRightColumn()}

                </div>

                {/* {this.renderBottomMenu()} */}

            </div>
            </div>
            </>
        );
    }

    renderChordSymbols(){
        let key = this.state.key;
        key = key.replace("flat", "b");
        key = key.replace("sharp", "#");
        return (
            <div style={{marginTop: this.biggerMarginSizeVertical, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                {/* show the key if they don't specify it */}
                {!this.loading
                    && 
                <div style={{color: "#B0B0B0", marginRight: this.bigMarginSizeHorizontal, fontSize: this.xlFontSize}}><b>
                    Key - {key}</b>
                </div>}

                {this.loading && <div>Generating new chords...</div>}
                {this.state.chords.length > 0 && !this.loading && this.state.chords.map((chord) => {
                    let startq = chord.measureNumber * 4 + chord.beatNumber;
                    let endq = startq + chord.lengthInBeats;

                    let chordOn = startq <= this.qNumber && this.qNumber < endq;

                    let idx = this.state.chords.indexOf(chord);

                    if (chordOn && this.chordOnIdx != idx){
                        // console.log(startq, endq, this.qNumber);
                        this.chordOnIdx = idx;
                        this.setState({scaleNotes: chord.scaleNotes});
                    }

                    // let size = chordOn ? 36 : 30;
                    let size = this.xlFontSize;
                    let color = chordOn || this.state.chordSelectedIdx == idx ? this.activeColor : '';

                    let cursor;
                    let clazz;


                    if (this.qNumber == -1){
                        cursor = 'pointer';
                        clazz="icon";
                    } else {
                        cursor = 'auto';
                        clazz="";
                    }


                    return (<div style={{margin: this.smallMarginSizeHorizontal, marginTop: 0, marginBottom: 0, fontSize: size, color: color, cursor: cursor}}  className={clazz}
                                onClick={() => {if (this.qNumber == -1){
                                                    if (this.state.chordSelectedIdx != idx){
                                                        this.setChordSelected(idx, chord);
                                                        this.clearAllNotes();
                                                        this.applyBassNotes(chord.measureNumber, chord.beatNumber);
                                                        this.applyHarmonyNotes(chord.measureNumber, chord.beatNumber);
                                                        this.setKeyMapping(idx);
                                                    } else {
                                                        this.setChordSelected(-1, null);
                                                        this.clearAllNotes();
                                                    }
                                                }
                                }}
                            >
                                {chord.symbol}
                            </div>);
                })}
                <div className="active" style={{marginLeft: this.bigMarginSizeHorizontal, display: 'flex', flexDirection: 'row', justifyContent: 'center', cursor: 'pointer',}}
                                onClick={() => {if (!this.loading){this.endTimeLoop(); this.startTimeLoop();}}}
                                >
                            <IoPlayCircleOutline size={70}/>
                    </div>
                
                <div className="poopy" style={{marginLeft: this.bigMarginSizeHorizontal, display: 'flex', flexDirection: 'row', justifyContent: 'center', cursor: 'pointer',}}
                        onClick={() => {if (!this.loading){this.endTimeLoop();}}}
                        >
                            <IoPauseCircleOutline size={70}/>
                </div>
            </div>
        );
    }

    setChordSelected(idx, chord){
        if (idx == -1){
            this.setState({scaleNotes: []});
        }
        else {
            this.setState({scaleNotes: chord.scaleNotes});
        }
        this.setState({
            chordSelectedIdx: idx,
        });
    }


    renderComplexitySettings(){
        return (
            <div className="shadow" style={{marginRight: 35, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', borderRadius: 15, borderColor: "rgba(255, 255, 255, 0.2)", backgroundColor: "rgba(255, 255, 255, 0.03)", borderWidth: 3, borderStyle:'solid', paddingRight: this.bigMarginSizeHorizontal, paddingLeft: this.bigMarginSizeHorizontal, paddingTop: this.bigMarginSizeVertical, paddingBottom: this.bigMarginSizeVertical}}>
                <div style={{display: 'flex'}}>
                <div style={{fontSize: this.bigFontSize, }}><b>Chord Explorer</b></div>
                    <this.tooltip title={<div className="mainFont">Adjust the slider to explore variations of the generated chords. <div style={{height: 16, }}></div>Click "Advanced" to control parameters individually</div>}><div style={{fontSize: this.bigFontSize, marginLeft: '0.5vh', marginTop: '0.1vh',}}><IoHelpCircleOutline className="icon" size={25}/></div></this.tooltip>




                    <a className='anchor' href={this.state.chordsMidiFilePath} download="chords.mid">
                        {/* <div className='icon' style={{cursor: 'pointer', fontSize: this.smallFontSize, display: 'flex', flexDirection: 'row', justifyContent: 'center', borderRadius: 8, padding: 10, borderWidth: 2, borderStyle: 'solid', textColor: 'black'}}>
                            <div><b>Download MIDI</b></div> <div style={{marginTop: 2, marginLeft: 6}}><IoDownloadOutline/></div>
                        </div> */}
                     <this.tooltip title={<div className="mainFont">Download Chords as MIDI</div>}><div className="icon" style={{marginLeft: '1vh', marginTop: -6, cursor: "pointer"}} ><IoDownloadOutline size={25}/></div></this.tooltip>

                    </a>


                </div>
                {this.state.highMediumLowSettings == 'low' && this.renderLowLevelSettings()}
                {this.state.highMediumLowSettings == 'medium' && this.renderMediumLevelSettings()}
                <div onClick={event => this.handleBasicOrAdvanced(event, "")} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', fontSize: this.bigFontSize, marginTop: this.bigMarginSizeVertical,}}>
                    <div style={{marginRight: this.smallMarginSizeHorizontal, cursor: 'pointer', }} className="icon">Basic</div>
                    <div style={{marginTop: 4}}><Switch
                        // onChange={this.handleBasicOrAdvanced}
                        className="switch"
                        disabled={true}
                        checked={this.state.highMediumLowSettings == "low"}
                        onColor={this.color2}
                        onHandleColor={this.color1}
                        handleDiameter={20}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={15}
                        width={42}
                    /></div>
                    <div style={{marginLeft: this.smallMarginSizeHorizontal, cursor: 'pointer', }} className="icon" >Advanced</div>
                </div>
            </div>
        );
    }

    handleBasicOrAdvanced(event, basicOrAdvanced){
        let newSetting = "";
        if (basicOrAdvanced == "basic"){
                newSetting = "medium";
        } else if (basicOrAdvanced == "advanced"){
                newSetting = "low";
        } else {
            if (this.state.highMediumLowSettings == "low"){
                newSetting = "medium";
            } else {
                newSetting = "low";
            }   
        }
        if (newSetting == ""){
            alert("bug found D: please refresh the page!");
        }
        this.setState({highMediumLowSettings: newSetting})
    }

    renderRightColumn(){
        let loadingMargin = this.loading ? 10 : 0;
        let mainButtonsMargin = 65;
        return (
            <div className="shadow" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', borderRadius: 15, borderColor: "rgba(255, 255, 255, 0.2)", backgroundColor: "rgba(255, 255, 255, 0.03)", borderWidth: 3, borderStyle:'solid', paddingRight: this.bigMarginSizeHorizontal, paddingLeft: this.bigMarginSizeHorizontal, paddingTop: this.bigMarginSizeVertical, paddingBottom: this.bigMarginSizeVertical}}>
                    <div style={{fontSize: this.bigFontSize, marginBottom: this.smallMarginSizeVertical}}><b>New Chords</b></div>

            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: this.bigMarginSizeVertical, paddingRight: 12}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 2, paddingBottom: 2}}>
                    {this.renderKeySetting()}
                    {this.renderNumberOfBarsSetting()}
                </div>
                <div style={{width: 80, }}>
                {!this.loading &&
                    // <this.tooltip title={
                    //     <div>
                    //       Generate new chords!
                    //     </div>
                    //   }>
                    <div
                        className="normal"
                        style={{margin: 20, marginTop: 0, marginBottom: 5, cursor: 'pointer'}}
                        onClick={() => {
                            this.endTimeLoop();
                            if (this.state.highMediumLowSettings == 'low'){
                                this.fetchNewLoop(this.state.settings);
                            } else if (this.state.highMediumLowSettings == 'medium'){
                                let settings = this.randomizeLowLevelSettings(Number(this.state.mediumLevelComplexity));
                                this.setState({
                                    settings: settings,
                                });
                                this.fetchNewLoop(settings);
                            }
                        }}>
                                <IoReloadCircleOutline  size={80}/>
                    </div>
                    // </this.tooltip>
                }
                <div
                    style={{marginLeft: loadingMargin+15, marginRight: loadingMargin, marginBottom: loadingMargin+1, marginTop: 10, }}>
                        <ClipLoader size={60} color='white' loading={this.loading}/>
                </div>
                </div>
            </div>
            {/* </this.tooltip> */}

            </div>
        );
    }

    getDownloadMIDIFileName(){
        let arr = this.state.chords.map((chord) => chord.symbol);
        let str = "";
        for (let el of arr){
            str += el;
            str += " ";
        }
        str = str.substring(0, str.length - 1);
        return str;
    }


    randomizeLowLevelSettings(complexity){

        let settings = this.state.settings;

        let sliderCenter;
        let switchProb;

        if (complexity == null){
            // completely random
            // sliderCenter = Math.random()*5-1;
            // switchProb = Math.random();

            settings.voicingComplexity = this.getProbabilisticSliderValue(Math.random()*5-1);
            settings.passingChordFrequency = this.getProbabilisticSliderValue(Math.random()*5-1);

            settings.majorTwoFive = this.getProbabilisticSwitchValue(Math.random());
            settings.minorTwoFive = this.getProbabilisticSwitchValue(Math.random());
            settings.diminished = this.getProbabilisticSwitchValue(Math.random());
            settings.modalInterchange = this.getProbabilisticSwitchValue(Math.random());
            settings.primaryDominant = this.getProbabilisticSwitchValue(Math.random());
            settings.secondaryDominant = this.getProbabilisticSwitchValue(Math.random());
            settings.substituteDominant = this.getProbabilisticSwitchValue(Math.random());

            this.setState({
                settings: settings,
            })
        } else { // complexity = 0, 1, 2, 3, 4
            sliderCenter = complexity;
            switchProb = complexity/5+0.05;

            settings.voicingComplexity = this.getProbabilisticSliderValue(sliderCenter);
            settings.passingChordFrequency = this.getProbabilisticSliderValue(0);
            settings.closedOrOpen = this.getProbabilisticSwitchValue(0.5) ? "closed" : "open";

            settings.majorTwoFive = this.getProbabilisticSwitchValue(0.25);
            settings.minorTwoFive = this.getProbabilisticSwitchValue(Math.max(switchProb-0.2, 0));
            settings.diminished = this.getProbabilisticSwitchValue(0.25);
            settings.modalInterchange = this.getProbabilisticSwitchValue(Math.max(switchProb-0.2, 0));
            settings.primaryDominant = this.getProbabilisticSwitchValue(0.3);
            settings.secondaryDominant = this.getProbabilisticSwitchValue(switchProb-0.1);
            settings.substituteDominant = this.getProbabilisticSwitchValue(Math.max(switchProb-0.4, 0));

            return settings;
        }
    }

    getProbabilisticSwitchValue(prob){
        return Math.random() < prob;
    }

    getProbabilisticSliderValue(center){
        let probs = [[0.6, 0.32, 0.08, 0, 0], // must sum to 1 or null may be returned
                     [0.175, 0.6, 0.175, 0.05, 0],
                     [0.05, 0.15, 0.6, 0.15, 0.05],
                     [0, 0.05, 0.175, 0.6, 0.175],
                     [0, 0, 0.08, 0.32, 0.6]];

        let vals = probs[center];

        let probSoFar = 1;

        for (let i = 0; i < vals.length; i++){
            let val = vals[i];
            if (Math.random() < val/probSoFar){
                return i;
            }
            probSoFar -= val;
        }
    }

    renderMiddleColumn(){
        return (
            <div className="shadow" style={{marginRight: 35, fontSize: this.bigFontSize, display: 'flex', flexDirection: 'column', gap: this.mediumMarginSizeVertical, alignItems: 'center', justifyContent: 'space-around', borderRadius: 15, borderColor: "rgba(255, 255, 255, 0.2)", backgroundColor: "rgba(255, 255, 255, 0.03)", borderWidth: 3, borderStyle:'solid', paddingRight: this.bigMarginSizeHorizontal, paddingLeft: this.bigMarginSizeHorizontal, paddingTop: this.bigMarginSizeVertical, paddingBottom: this.bigMarginSizeVertical}}>

                {this.renderTempoSetting()}

                {this.renderDrumSetting()}

                {WebMidi.supported ? 
                <div className="cell" style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 8, marginBottom: 8}}>
                    <div style={{fontSize: this.bigFontSize, }}>MIDI Input</div>
                    <this.tooltip title={
                        <div className="mainFont">
                          Connect your external MIDI keyboard to play along. <div style={{height: 16}}></div>If you don't have a MIDI keyboard, you can use your computer keyboard to play along
                        </div>
                      }>
                    <div style={{marginRight: this.smallMarginSizeHorizontal,marginLeft: '0.5vh', marginTop: 6}}><IoHelpCircleOutline className="icon" size={25}/></div></this.tooltip>
                    <Dropdown
                        options={this.state.midiInputs}
                        value={this.state.activeMidiInput}
                        onChange={changeEvent => {
                            this.setState({activeMidiInput: changeEvent.value});
                            this.handleMidiInputDropdownChange(changeEvent.value);
                        }}
                        style={{color: 'black',}}
                        className='DropDown'
                        controlClassName='ControlClass'
                        placeHolderClassName='PlaceHolderClass'

                    />
                </div> : this.isMobile ? <div></div> : <div className="cell" style={{fontSize: 12, maxWidth: 150}}>Switch to Google Chrome to use MIDI input!</div>
                }

                <div className="cell" style={{ display: 'flex', }}>
                    No Wrong Notes
                    <this.tooltip title={
                        <div className="mainFont">
                          When enabled, notes you play on your external or computer keyboard will automatically fit the harmony
                        </div>
                      }>
                    <div style={{marginRight: this.smallMarginSizeHorizontal,marginLeft: '0.5vh', marginTop: '0.1vh'}}><IoHelpCircleOutline className="icon" size={25}/></div></this.tooltip>
                    <div style={{marginTop: 4}}><Switch
                        onChange={(val) => {this.setState({noWrongNotesMode: val})}}
                        checked={this.state.noWrongNotesMode}
                        onColor={this.color2}
                        onHandleColor={this.color1}
                        handleDiameter={20}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={15}
                        width={42}
                    /></div>
                </div>

            </div>
        )
    }

    renderKeySetting(){
        return (
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 8}}>
                <div style={{fontSize: this.bigFontSize, marginRight: this.smallMarginSizeHorizontal, }}>Key</div>
                <div style={{display: 'flex', flexDirection: this.isMobile ? 'column' : 'row'}}>
                {this.state.settings.keyMajorMinor == 'major' && <div style={{width: 100}}><Dropdown
                    options={this.majorKeyOptions}
                    value={this.state.settings.keyValue}
                    onChange={changeEvent => {
                        let settings = this.state.settings;
                        settings.keyValue = changeEvent.value;
                        this.setState({settings: settings});
                    }}
                    style={{color: 'black',}}
                    className='DropDown'
                    controlClassName='ControlClass'
                    placeHolderClassName='PlaceHolderClass'

                /></div>}
                {this.state.settings.keyMajorMinor == 'minor' && <div style={{width: 100}}><Dropdown
                    options={this.minorKeyOptions}
                    value={this.state.settings.keyValue}
                    onChange={changeEvent => {
                        let settings = this.state.settings;
                        settings.keyValue = changeEvent.value;
                        this.setState({settings: settings});
                    }}
                    style={{color: 'black'}}
                    className='DropDown'
                    controlClassName='ControlClass'
                    placeHolderClassName='PlaceHolderClass'
                /></div>}
                <div style={{width: 100,}}>
                <Dropdown
                    options={['random', 'major', 'minor']}
                    value={this.state.settings.keyMajorMinor}
                    onChange={changeEvent => {
                        let settings = this.state.settings;
                        settings.keyMajorMinor = changeEvent.value;
                        this.setState({settings: settings});

                        if (settings.keyMajorMinor == "major" && !this.majorKeyOptions.includes(settings.keyValue)){
                            settings.keyValue = this.majorKeyOptions[this.minorKeyOptions.indexOf(settings.keyValue)];
                        } else if (settings.keyMajorMinor == "minor" && !this.minorKeyOptions.includes(settings.keyValue)){
                            settings.keyValue = this.minorKeyOptions[this.majorKeyOptions.indexOf(settings.keyValue)];
                        }

                    }}
                    style={{color: 'black'}}
                    className='DropDown'
                    controlClassName='ControlClass'
                    placeHolderClassName='PlaceHolderClass'
                /></div></div>
            </div>
        );
    }

    renderNumberOfBarsSetting(){
        return (
            <div style={{display: 'flex', flexDirection: 'row', paddingTop: 2, paddingBottom: 2, justifyContent: 'center', alignItems: 'center', marginTop: this.smallMarginSizeVertical}}>
                <div style={{fontSize: this.bigFontSize, marginRight: this.smallMarginSizeHorizontal}}>Length</div>
                <Dropdown
                    options={['2', '4']}
                    value={this.state.settings.numberOfBars}
                    onChange={changeEvent => {
                        let settings = this.state.settings;
                        settings.numberOfBars = changeEvent.value;
                        this.setState({settings: settings});
                    }}
                    style={{color: 'black'}}
                    className='DropDown'
                    controlClassName='ControlClass'
                    placeHolderClassName='PlaceHolderClass'

                />
            </div>
        );
    }

    setTempo(tempo){
        Tone.Transport.bpm.value = tempo;
        if (this.state.chords.length > 0){
            this.setState({tempo: tempo}, () => this.refreshChordsMIDITrack());
        } else {
            this.setState({tempo: tempo});
        }
    }

    renderTempoSetting(){

        return (
            
            <div className="cell" style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', fontSize: this.bigFontSize, }}>
                <div style={{marginRight: this.smallMarginSizeHorizontal}}>Tempo</div>
                <div style={{width: this.isMobile ? 60 : 120, marginTop: 6}}><Slider
                    min={60}
                    max={180}
                    step={1}
                    onChange={value => {
                        this.setTempo(value);
                    }}
                    value={this.state.tempo}
                    handleStyle={{
                        backgroundColor: this.color1,
                        borderColor: this.color1,
                        height:20,
                        width:20,
                        borderRadius: 10,
                        marginTop: -6,
                        marginBottom: 3
                    }}
                    trackStyle={{
                        backgroundColor: this.color2,
                        height:8,
                        width:130,
                        borderRadius:5,
                    }}
                    railStyle={{
                        height:8,
                        borderRadius:5,
                    }}
                /></div>
                <div style={{marginLeft: this.smallMarginSizeHorizontal, width:40, }}>{this.state.tempo}</div>
            </div>
        );
    }

    renderDrumSetting(){

        return (
            
            <div className="cell" style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', fontSize: this.bigFontSize, }}>
                <div style={{marginRight: this.smallMarginSizeHorizontal}}>Drums</div>
                <div style={{width: this.isMobile ? 60 : 120, marginTop: 6, marginRight: this.smallMarginSizeHorizontal}}><Slider
                    min={0}
                    max={4}
                    step={1}
                    onChange={value => {
                        this.setState({drumComplexity: value});
                        let kickPatterns = this.drumPatterns.kick[value];
                        let snarePatterns = this.drumPatterns.snare[value];
                        let hatPatterns = this.drumPatterns.hat[value];
            
                        this.activeDrums = {
                            kickPattern: kickPatterns[Math.floor(Math.random()*kickPatterns.length)],
                            snarePattern: snarePatterns[Math.floor(Math.random()*snarePatterns.length)],
                            hatPattern: hatPatterns[Math.floor(Math.random()*hatPatterns.length)],
                        };
                        this.refreshDrumsMIDITrack();
                    }}
                    value={this.state.drumComplexity}
                    handleStyle={{
                        backgroundColor: this.color1,
                        borderColor: this.color1,
                        height:20,
                        width:20,
                        borderRadius: 10,
                        marginTop: -6,
                        marginBottom: 3
                    }}
                    trackStyle={{
                        backgroundColor: this.color2,
                        height:8,
                        width:130,
                        borderRadius:5,
                    }}
                    railStyle={{
                        height:8,
                        borderRadius:5,
                    }}
                /></div>

                <this.tooltip title={<div className="mainFont">Download Drums as MIDI</div>}><a className='anchor' href={this.drumsMidiFilePath} download="drums.mid">
                    <div className="icon" style={{cursor: "pointer", marginRight: this.smallMarginSizeHorizontal}}><IoDownloadOutline size={25}/></div>
                </a></this.tooltip>

                <div className="icon" style={{cursor: "pointer" }} onClick={() => this.toggleDrumMuted()}>{this.state.drumMuted ? <IoVolumeMute size={28}/> : <IoVolumeHigh size={28}/>}</div> 

            </div>
        );
    }

    refreshDrumsMIDITrack(){
        // // write MIDI file for download
        // // Start with a new track
        // let track = new MidiWriter.Track();

        // // Define an instrument (optional):
        // track.addEvent(new MidiWriter.ProgramChangeEvent({instrument: 1}));

        // Add some notes:
        let kicks = this.activeDrums.kickPattern;
        let snares = this.activeDrums.snarePattern;
        let hats = this.activeDrums.hatPattern;


        let numberOfTracks = 3; // number of instruments (for now, just kick/snare/hat)
        let tracks = [];
        for (let i = 0; i < numberOfTracks; i++){
            tracks.push(new MidiWriter.Track());
        }
        for (let i = 0; i < numberOfTracks; i++){
            let track = tracks[i];
            track.addEvent(new MidiWriter.ProgramChangeEvent({instrument: 1}));
        }


        this.addNotesToDrumTrack(kicks, "C2", tracks[0]);
        this.addNotesToDrumTrack(snares, "E2", tracks[1]);
        this.addNotesToDrumTrack(hats, "F#2", tracks[2]);
        

        for (let i = 1; i < numberOfTracks; i++){
            tracks[0].mergeTrack(tracks[i]);
        }

        tracks[0].setTempo(this.state.tempo);

        // Generate a data URI
        var write = new MidiWriter.Writer(tracks[0]);
        // console.log(write.dataUri());
        this.drumsMidiFilePath = write.dataUri();
    }

    addNotesToDrumTrack(notes, noteName, track){
        let velocity = 0;
        
        for (let note of notes){
            
            if (note == 0){
                velocity = 0;
            } else if (note == 1){
                velocity = 60;
            } else if (note == 2){
                velocity = 45;
            }
            
            let midiNote = new MidiWriter.NoteEvent({pitch: noteName, duration: '16', velocity: velocity,});
            track.addEvent(midiNote);
        }
    }

    toggleDrumMuted(){
        if (this.state.drumMuted){
            this.setState({drumMuted: false});
        } else {
            this.setState({drumMuted: true});
        }
    }

    renderMediumLevelSettings(){
        return (
            <div style={{display: 'flex', flexDirection: 'column', marginBottom: this.smallMarginSizeVertical}}>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    {/* <div style={{fontSize: this.bigFontSize, marginBottom: this.smallMarginSizeVertical}}>Complexity</div> */}
                    <div style={{width: 120, marginTop: this.bigMarginSizeVertical, marginBottom: this.smallMarginSizeVertical}}>
                    <Slider
                        min={0}
                        max={4}
                        step={1}
                        onChange={value => {
                            let settings = this.randomizeLowLevelSettings(value);
                            this.setState({
                                settings: settings,
                                mediumLevelComplexity: value,
                            }, () => {
                                if (this.state.chords.length > 0){
                                    let obj = this.applyAllSettings(this.getHarmonyState());
                                    obj = this.updateVoicings(settings.voicingComplexity, settings.closedOrOpen, obj);
                                    this.setHarmonyState(obj);
                                }
                            });
                        }}
                        value={this.state.mediumLevelComplexity}
                        handleStyle={{
                            backgroundColor: this.color1,
                            borderColor: this.color1,
                            height:20,
                            width:20,
                            borderRadius: 10,
                            marginTop: -6,
                            marginBottom: 3
                        }}
                        trackStyle={{
                            backgroundColor: this.color2,
                            height:8,
                            width:130,
                            borderRadius:5,
                        }}
                        railStyle={{
                            height:8,
                            borderRadius:5,
                        }}
                    />
                    </div>
                </div>



            </div>
        );
    }

    renderLowLevelSettings(){

        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: this.smallFontSize, marginTop: this.smallMarginSizeVertical}}>
            <div style={{display: 'flex', flexDirection: 'row', marginRight: this.smallMarginSizeHorizontal}}>
                <div style={{display: 'flex', flexDirection: 'column', }}>

                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
{/* <this.tooltip title={
                        <div>
                          Controls the sophistication of the {tt.tooltips.tag([], {name: ""}, tt.tooltips.voicing)} used for each chord
                        </div>
                      }> */}
                        <div style={{marginTop: this.smallMarginSizeVertical, marginRight: this.smallMarginSizeHorizontal}}>Voicing Complexity</div>
                        {/* </this.tooltip> */}
                        <div style={{width: 70, marginTop: 11}}>
                        <Slider
                            min={0}
                            max={4}
                            step={1}
                            onChange={value => {
                                let settings = this.state.settings;
                                settings.voicingComplexity = value;
                                this.setState({settings: settings});
                                if (this.state.chords.length > 0){
                                    this.setHarmonyState(this.updateVoicings(value, this.state.settings.closedOrOpen, this.getHarmonyState()));
                                }
                            }}
                            value={this.state.settings.voicingComplexity}
                            handleStyle={{
                                backgroundColor: this.color1,
                                borderColor: this.color1,
                            }}
                            trackStyle={{
                                backgroundColor: this.color2
                            }}
                        />
                        </div>
                    </div>

                    <div onClick={this.handleClosedOrOpen} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: this.smallMarginSizeVertical}}>
                    {/* <this.tooltip title={
                        <div>
                          Controls whether each chord's {tt.tooltips.tag([], {name: ""}, tt.tooltips.voicing)} is {tt.tooltips.tag([], {name: ""}, tt.tooltips.openVoicing)} or {tt.tooltips.tag([], {name: ""}, tt.tooltips.closedVoicing)}
                        </div>
                      }> */}
                          <div className="lowlevelsetting" style={{marginRight: this.smallMarginSizeHorizontal, }}>Open Voicing</div>
                          {/* </this.tooltip> */}
                        <Switch
                        className="switch"
                        disabled={true}
                            checked={this.state.settings.closedOrOpen == "open"}
                            onColor={this.color2}
                            onHandleColor={this.color1}
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={15}
                            width={42}
                        />
                    </div>

                    <div onClick={this.handleModalInterchange} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: this.smallMarginSizeVertical}}>
                    {/* <this.tooltip title={
                        <div>
                            Modal interchange chords are borrowed from a different {tt.tooltips.tag([], {name: ""}, tt.tooltips.mode)} than the principal mode of the song. They provide colour and variety
                        </div>
                      }> */}
                        <div className="lowlevelsetting" style={{marginRight: this.smallMarginSizeHorizontal, }}>Modal Interchange</div>
                        {/* </this.tooltip> */}
                        <Switch
                        className="switch"
                        disabled={true}
                            checked={this.state.settings.modalInterchange}
                            onColor={this.color2}
                            onHandleColor={this.color1}
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={15}
                            width={42}
                        />
                    </div>


                </div>

                <div style={{display: 'flex', flexDirection: 'column', marginLeft: this.bigMarginSizeVertical}}>

                   

                    {/* <div onClick={this.handleDiminished} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: this.smallMarginSizeVertical}}> */}
                    {/* <this.tooltip title={
                    <div>Diminished chords are colorful but very tense and can be inserted before other chords to add direction to a chord progression</div>
                    }> */}
                          {/* <div className="lowlevelsetting" style={{marginRight: this.smallMarginSizeHorizontal, }}>Diminished</div> */}
                          {/* </this.tooltip> */}
                        {/* <Switch
                        className="switch"
                        disabled={true}
                            checked={this.state.settings.diminished}
                            onColor={this.color2}
                            onHandleColor={this.color1}
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={15}
                            width={42}
                        />
                    </div> */}

                    <div onClick={this.handleSecondaryDominant} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: this.smallMarginSizeVertical}}>
                    {/* <this.tooltip title={
                        <div>
                          Secondary dominants are colorful {tt.tooltips.tag([], "", tt.tooltips.dominant)} chords which can be inserted before other chords to add direction to a chord progression
                        </div>
                      }> */}
                          <div className="lowlevelsetting" style={{marginRight: this.smallMarginSizeHorizontal, }}>Secondary Dominant</div>
                          {/* </this.tooltip> */}
                        <Switch
                        className="switch"
                        disabled={true}
                            checked={this.state.settings.secondaryDominant}
                            onColor={this.color2}
                            onHandleColor={this.color1}
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={15}
                            width={42}
                        />
                    </div>


                    <div onClick={this.handleMajorTwoFives} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: this.smallMarginSizeVertical}}>
                    {/* <this.tooltip title={
                        <div>
                          A 2-5 aka "two-five" is a two-chord sequence which adds a sense of tension and resolution to a chord progression
                        </div>
                      }> */}
                          <div className="lowlevelsetting" style={{marginRight: this.smallMarginSizeHorizontal, }}>Major 2-5</div>
                          {/* </this.tooltip> */}
                        <Switch
                        className="switch"
                        disabled={true}
                            checked={this.state.settings.majorTwoFive}
                            onColor={this.color2}
                            onHandleColor={this.color1}
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={15}
                            width={42}
                        />
                    </div>

                    <div onClick={this.handleMinorTwoFives} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: this.smallMarginSizeVertical}}>
                    {/* <this.tooltip title={
                        <div>
                          A 2-5 aka "two-five" is a two-chord sequence which adds a sense of tension and resolution to a chord progression
                        </div>
                      }> */}
                          <div className="lowlevelsetting" style={{marginRight: this.smallMarginSizeHorizontal, }}>Minor 2-5</div>
                          {/* </this.tooltip> */}
                        <Switch
                        className="switch"
                        disabled={true}
                            checked={this.state.settings.minorTwoFive}
                            onColor={this.color2}
                            onHandleColor={this.color1}
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={15}
                            width={42}
                        />
                    </div>

                    {/* <div onClick={this.handleSubstituteDominant} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: this.smallMarginSizeVertical}}> */}
                    {/* <this.tooltip title={
                        <div>
                          Substitute dominants are super colorful, jazzy {tt.tooltips.tag([], "", tt.tooltips.dominant)} chords can be inserted before other chords to add direction to a chord progression
                        </div>
                    }> */}
                          {/* <div className="lowlevelsetting" style={{marginRight: this.smallMarginSizeHorizontal, }}>Substitute Dominant</div> */}
                          {/* </this.tooltip> */}
                        {/* <Switch
                        className="switch"
                        disabled={true}
                            checked={this.state.settings.substituteDominant}
                            onColor={this.color2}
                            onHandleColor={this.color1}
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={15}
                            width={42}
                        />
                    </div> */}

                </div>

            </div>

            </div>
        );
    }

    handleMajorTwoFives(){
        let settings = this.state.settings;
        settings.majorTwoFive = !settings.majorTwoFive;
        this.setState({settings: settings});
        if (this.state.chords.length > 0 && this.state.highMediumLowSettings == "low"){
            if (settings.majorTwoFive){
                this.setHarmonyState(this.addAlternateChord("majorTwoFive", this.getHarmonyState()));
            } else {
                this.setHarmonyState(this.removeAlternateChords(["TwoInMajor25", "FiveInMajor25"], this.getHarmonyState()));
            }
        }
    }

    handleMinorTwoFives(){
        let settings = this.state.settings;
        settings.minorTwoFive = !settings.minorTwoFive;
        this.setState({settings: settings});
        if (this.state.chords.length > 0 && this.state.highMediumLowSettings == "low"){
            if (settings.minorTwoFive){
                this.setHarmonyState(this.addAlternateChord("minorTwoFive", this.getHarmonyState()));
            } else {
                this.setHarmonyState(this.removeAlternateChords(["TwoInMinor25", "FiveInMinor25"], this.getHarmonyState()));
            }
        }
    }

    handleDiminished(){
        // let settings = this.state.settings;
        // settings.diminished = !settings.diminished;
        // this.setState({settings: settings});
        // if (this.state.chords.length > 0 && this.state.highMediumLowSettings == "low"){
        //     if (settings.diminished){
        //         this.setHarmonyState(this.addAlternateChord("diminished", this.getHarmonyState()));
        //     } else {
        //         this.setHarmonyState(this.removeAlternateChords(["diminished"], this.getHarmonyState()));
        //     }
        // }
    }

    handleModalInterchange(){
        let settings = this.state.settings;
        settings.modalInterchange = !settings.modalInterchange;
        this.setState({settings: settings});
        if (this.state.chords.length > 0 && this.state.highMediumLowSettings == "low"){
            if (settings.modalInterchange){
                this.setHarmonyState(this.addAlternateChord("modalInterchange", this.getHarmonyState()));
            } else {
                this.setHarmonyState(this.removeAlternateChords(["modalInterchange"], this.getHarmonyState()));
            }
        }
    }

    handleClosedOrOpen(){

        let settings = this.state.settings;
        let str;
        if (settings.closedOrOpen == "closed"){
            str = "open";
        } else {
            str = "closed";
        }
        settings.closedOrOpen = str;
        this.setState({settings: settings});
        if (this.state.chords.length > 0 && this.state.highMediumLowSettings == "low"){
            this.setHarmonyState(this.updateVoicings(this.state.settings.voicingComplexity, str, this.getHarmonyState()));
        }
    }

    handleSecondaryDominant(){
        let settings = this.state.settings;
        settings.secondaryDominant = !settings.secondaryDominant;
        this.setState({settings: settings});
        if (this.state.chords.length > 0 && this.state.highMediumLowSettings == "low"){
            if (settings.secondaryDominant){
                this.setHarmonyState(this.addAlternateChord("secondaryDominant", this.getHarmonyState()));
            } else {
                this.setHarmonyState(this.removeAlternateChords(["secondaryDominant"], this.getHarmonyState()));
            }
        }
    }

    handleSubstituteDominant(){
        // let settings = this.state.settings;
        // settings.substituteDominant = !settings.substituteDominant;
        // this.setState({settings: settings});
        // if (this.state.chords.length > 0 && this.state.highMediumLowSettings == "low"){
        //     if (settings.substituteDominant){
        //         this.setHarmonyState(this.addAlternateChord("substituteDominant", this.getHarmonyState()));
        //     } else {
        //         this.setHarmonyState(this.removeAlternateChords(["substituteDominant"], this.getHarmonyState()));
        //     }
        // }
    }


    fetchNewLoop(settings){
        this.loading = true;
        let _this = this;
        let URLstring = 'https://irix6aarbbbyyl5bjgw6im5h7a0plnag.lambda-url.us-east-2.on.aws/Prod/compose?' + new URLSearchParams(settings);
        fetch(URLstring)
        .then((response) =>
        response.json())
        .then((responseData) => {
            if (!!responseData.chords){
                this.requestCount = 0;
                this.barsInLoop = responseData.numberOfBars;
                this.loading = false;

                this.initChordsBassAndHarmonyNotes(responseData);

                let chordClone = JSON.parse(JSON.stringify(responseData.chords));

                chordClone.forEach((chord) => {
                    if (chord.scale === 'MajorPentatonic'){
                        console.log('found one!')
                    }
                })

                this.setState({
                    dependentChords: {
                        majorTwoFive: responseData.majorTwoFives,
                        minorTwoFive: responseData.minorTwoFives,
                        // diminished: responseData.diminished,
                        secondaryDominant: responseData.secondaryDominants,
                        substituteDominant: responseData.substituteDominants,
                    },
                    modalInterchange: responseData.modalInterchange,
                    baseChords: chordClone,
                }, () => this.setHarmonyState(this.applyAllSettings(this.getHarmonyState())));

                let keyIsRandom = false;
                if (this.state.settings.keyMajorMinor == "random" || this.state.settings.keyValue == "random" ){
                    keyIsRandom = true;
                }

                this.setState({
                    key: responseData.key,
                    keyIsRandom: keyIsRandom,
                });




                let scaleNotes;

                let chord = responseData.chords[0]

                if (chord.scaleNotes.length > 6){
                    scaleNotes = this.trimToSix(chord);
                }

                let offset = this.getOffset(scaleNotes);

                this.lastScaleNoteMap = JSON.parse(JSON.stringify(this.scaleNoteMap));
                this.scaleNoteMap = this.createScaleNoteMap(scaleNotes, offset).scaleNoteMap;

                this.lastScaleNoteMapForMIDIInput = JSON.parse(JSON.stringify(this.scaleNoteMapForMIDIInput));
                this.scaleNoteMapForMIDIInput = this.createScaleNoteMap(scaleNotes, offset).scaleNoteMapForMIDIInput;
            }
            else
            {
                this.requestCount++;
                if (this.requestCount > 9){
                    alert('This is embarrassing... Something went wrong. Please try again :D');
                    this.loading = false;
                    this.forceUpdate();
                } else {
                    this.fetchNewLoop(settings);
                }

            }
        })
    }

    setHarmonyState(harmonyState){
        this.setState({
            harmonyNotes: harmonyState.harmonyNotes,
            bassNotes: harmonyState.bassNotes,
            chords: harmonyState.chords,
        }, () => this.refreshChordsMIDITrack());
    }

    getHarmonyState(){
        return {
            harmonyNotes: this.state.harmonyNotes,
            bassNotes: this.state.bassNotes,
            chords: this.state.chords,
        };
    }

    initChordsBassAndHarmonyNotes(responseData){
        let harmonyNotes = [];

        for (let i = 0; i < responseData.chords.length; i++){
            let chord = responseData.chords[i];
            for (let i2 = 0; i2 < chord.harmonyNotes.length; i2++){
                let note = chord.harmonyNotes[i2];
                if (note.complexity == this.state.settings.voicingComplexity && note.closedOrOpen == this.state.settings.closedOrOpen){
                    harmonyNotes.push(...note.notes);
                }
            }
        }

        this.setState({
            chords: responseData.chords,
            bassNotes: responseData.chords.map((chord) => chord.bassNote),
            harmonyNotes: harmonyNotes,
        }, () => this.refreshChordsMIDITrack());
    }

    updateVoicings(complexity, closedOrOpen, harmonyState){
        let harmonyNotes = [];

        for (let i = 0; i < harmonyState.chords.length; i++){
            let chord = harmonyState.chords[i];
            for (let i2 = 0; i2 < chord.harmonyNotes.length; i2++){
                let note = chord.harmonyNotes[i2];
                if (note.complexity == complexity && note.closedOrOpen == closedOrOpen){
                    harmonyNotes.push(...note.notes);
                }
            }
        }

        this.updateLengthOfChords(harmonyState.chords, harmonyNotes, []);

        harmonyState.harmonyNotes = harmonyNotes;
        return harmonyState;

    }

    applyAllSettings(harmonyState){

        let chordTypes = ["majorTwoFive", "minorTwoFive", "modalInterchange", "secondaryDominant"];
        // chordTypes = [...chordTypes, "diminished", "substituteDominant"];
        for (let i = 0; i < chordTypes.length; i++){
            let type = chordTypes[i];
            if (this.state.settings[type]){
                harmonyState = this.addAlternateChord(type, harmonyState);
            }
            else {
                let typeArr;
                if (type == "majorTwoFive"){
                    typeArr = ["TwoInMajor25", "FiveInMajor25"];
                } else if (type == "minorTwoFive"){
                    typeArr = ["TwoInMinor25", "FiveInMinor25"];
                } else {
                    typeArr = [type];
                }
                harmonyState = this.removeAlternateChords(typeArr, harmonyState);
            }
        }

        return harmonyState;
    }

    addAlternateChord(chordType, harmonyState){

        // for modal interchange:
        if (chordType == "modalInterchange")
        {
            this.shuffleArray(this.state.modalInterchange);

            return this.tryModalInterchange(0, harmonyState);

            // if (!this.tryModalInterchange(0)){
            //     // if no chords are without removing other alternate chords => re-iterate, try to remove/re-add chord type(s)
            //     this.tryModalInterchange(1);
            // }

        } else if (chordType == "majorTwoFive" || chordType == "minorTwoFive"){

            this.shuffleArray(this.state.dependentChords[chordType])

            return this.tryTwoFive(chordType, 0, harmonyState);

            // if (!this.tryTwoFive(chordType, 0)){

            //     // if no chords are possible => re-iterate, only check if resolvesToID is in activeIDs
            //     if (!this.tryTwoFive(chordType, 1)){
            //         // no chords available for this chord type to resolve to!!!
            //     }
            // }

        }

        else {

            // for dependent chords:

            this.shuffleArray(this.state.dependentChords[chordType])

            return this.tryDependentChord(chordType, 0, harmonyState);

            // if (!this.tryDependentChord(chordType, 0)){

            //     // if no chords are possible => re-iterate, only check if resolvesToID is in activeIDs
            //     if (!this.tryDependentChord(chordType, 1)){
            //         // no chords available for this chord type to resolve to!!!
            //     }
            // }

        }

    }

    tryTwoFive(chordType, iteration, harmonyState){
        // shuffle then iterate through available chords for type

        for (let i = 0; i < this.state.dependentChords[chordType].length; i++){
            let candidate = this.state.dependentChords[chordType][i];


            let two = candidate.chords[0];
            let five = candidate.chords[1];

            // retrieve chords to replace
            let chordTwoReplaces;
            let chordBeforeCandidate;
            for (let i2 = 0; i2 < harmonyState.chords.length; i2++){
                let chord = harmonyState.chords[i2];
                if (chord.beatNumber == two.beatNumber && chord.measureNumber == two.measureNumber){
                    chordTwoReplaces = chord;
                } else if (chord.measureNumber > two.measureNumber || chord.measureNumber == two.measureNumber && chord.beatNumber > two.beatNumber){
                    chordBeforeCandidate = chord;
                }
            }

            let chordFiveReplaces;
            for (let i2 = 0; i2 < harmonyState.chords.length; i2++){
                let chord = harmonyState.chords[i2];
                if (chord.beatNumber == five.beatNumber && chord.measureNumber == five.measureNumber){
                    chordFiveReplaces = chord;
                } else if (chord.measureNumber > five.measureNumber || chord.measureNumber == five.measureNumber && chord.beatNumber > five.beatNumber){
                    chordBeforeCandidate = chord;
                }
            }

            if (iteration == 0){

                // check if resolvesToID is in activeIDs and NOT in resolvedToIDs (i.e. another chord already resolves there)...
                // also make sure this chord will not replace a chord in resolvedToIDs and it is replacing a base chord

                let twoReplacementOkay = true;
                if (!!chordTwoReplaces){
                    twoReplacementOkay = !this.resolvesToIDs.includes(chordTwoReplaces.ID) && this.state.baseChords.map((baseChord) => baseChord.ID).includes(chordTwoReplaces.ID);
                }

                let fiveReplacementOkay = true;
                if (!!chordFiveReplaces){
                    fiveReplacementOkay = !this.resolvesToIDs.includes(chordFiveReplaces.ID) && this.state.baseChords.map((baseChord) => baseChord.ID).includes(chordFiveReplaces.ID);
                }

                if (harmonyState.chords.map((chord) => chord.ID).includes(candidate.resolvesToID) && !this.resolvesToIDs.includes(candidate.resolvesToID) && twoReplacementOkay && fiveReplacementOkay){

                    this.resolvesToIDs.push(candidate.resolvesToID);

                    return this.replaceChords([[two, chordTwoReplaces], [five, chordFiveReplaces]], harmonyState);
                }

            } else if (iteration == 1){


                // if resolvesToID is in resolvedToIDs => check what resolves to it, and try to remove this/these chord type(s) then re-add it/them
                // if chord will replace a chord in resolvedToIDs => check what resolves to that chord, try to remove this/these chord type(s) then re-add it/them

                // if (this.state.activeIDs.includes(candidate.resolvesToID)){
                //     let chordsToAddBack = [];

                //     let chordToReplace = null;
                //     if (!!chordToReplace){

                //         if (!this.state.baseChords.map((baseChord) => baseChord.ID).includes(chordToReplace.ID)){
                //             chordsToAddBack.push(chordToReplace.chordType);
                //         }

                //         let chordchord = chordToReplace;

                //         while (this.resolvesToIDs.includes(chordchord.ID)){
                //             let chord = this.state.chords.filter((chord) => chord.resolvesToID == chordchord.ID)[0];
                //             chordsToAddBack.push(chord.chordType);
                //             chordchord = chord;

                //         }

                //     }

                //     if (this.resolvesToIDs.includes(candidate.resolvesToID)){
                //         let chordchord = this.state.chords.filter((chord) => chord.ID == candidate.resolvesToID);

                //         while (this.resolvesToIDs.includes(chordchord.ID)){
                //             let chord = this.state.chords.filter((chord) => chord.resolvesToID == chordchord.ID)[0];
                //             chordsToAddBack.push(chord.chordType);
                //             chordchord = chord;

                //         }
                //     }

                //     chordsToAddBack = [...new Set(chordsToAddBack)];

                //     // remove them!
                //     for (let i2 = 0; i2 < chordsToAddBack.length; i2++){
                //         let chordToAddBack = chordsToAddBack[i2];

                //         this.removeAlternateChord(chordToAddBack);
                //     }

                //     // get our dependent chord in there
                //     this.tryDependentChord(chordType, 0);

                //     // ... and lastly, try to add those back if possible
                //     for (let i2 = 0; i2 < chordsToAddBack.length; i2++){
                //         let chordToAddBack = chordsToAddBack[i2];

                //         this.tryDependentChord(chordToAddBack, 0);
                //     }


                //     return true;
                // }

            }

        }

        return harmonyState;
    }

    tryDependentChord(chordType, iteration, harmonyState){
        // shuffle then iterate through available chords for type

        for (let i = 0; i < this.state.dependentChords[chordType].length; i++){
            let candidate = this.state.dependentChords[chordType][i];

            // retrieve chord to replace
            let chordToReplace;
            let chordBeforeCandidate;
            for (let i2 = 0; i2 < harmonyState.chords.length; i2++){
                let chord = harmonyState.chords[i2];
                if (chord.beatNumber == candidate.beatNumber && chord.measureNumber == candidate.measureNumber){
                    chordToReplace = chord;
                } else if (chord.measureNumber > candidate.measureNumber || chord.measureNumber == candidate.measureNumber && chord.beatNumber > candidate.beatNumber){
                    chordBeforeCandidate = chord;
                }
            }

            if (iteration == 0){

                // check if resolvesToID is in activeIDs and NOT in resolvedToIDs (i.e. another chord already resolves there)...
                // also make sure this chord will not replace a chord in resolvedToIDs and it is replacing a base chord
                if (harmonyState.chords.map((chord) => chord.ID).includes(candidate.resolvesToID) && !this.resolvesToIDs.includes(candidate.resolvesToID)){
                    if (!!chordToReplace){
                        if (!this.resolvesToIDs.includes(chordToReplace.ID) && this.state.baseChords.map((baseChord) => baseChord.ID).includes(chordToReplace.ID)){

                            this.resolvesToIDs.push(candidate.resolvesToID);

                            return this.replaceChords([[candidate, chordToReplace]], harmonyState);

                        }
                    } else {

                        this.resolvesToIDs.push(candidate.resolvesToID);

                        return this.replaceChords([[candidate, chordToReplace]], harmonyState);
                    }
                }

            } else if (iteration == 1){


                // if resolvesToID is in resolvedToIDs => check what resolves to it, and try to remove this/these chord type(s) then re-add it/them
                // if chord will replace a chord in resolvedToIDs => check what resolves to that chord, try to remove this/these chord type(s) then re-add it/them

                // if (this.state.activeIDs.includes(candidate.resolvesToID)){
                //     let chordsToAddBack = [];

                //     if (!!chordToReplace){

                //         if (!this.state.baseChords.map((baseChord) => baseChord.ID).includes(chordToReplace.ID)){
                //             chordsToAddBack.push(chordToReplace.chordType);
                //         }

                //         let chordchord = chordToReplace;

                //         while (this.resolvesToIDs.includes(chordchord.ID)){
                //             let chord = this.state.chords.filter((chord) => chord.resolvesToID == chordchord.ID)[0];
                //             chordsToAddBack.push(chord.chordType);
                //             chordchord = chord;

                //         }

                //     }

                //     if (this.resolvesToIDs.includes(candidate.resolvesToID)){
                //         let chordchord = this.state.chords.filter((chord) => chord.ID == candidate.resolvesToID);

                //         while (this.resolvesToIDs.includes(chordchord.ID)){
                //             let chord = this.state.chords.filter((chord) => chord.resolvesToID == chordchord.ID)[0];
                //             chordsToAddBack.push(chord.chordType);
                //             chordchord = chord;

                //         }
                //     }

                //     chordsToAddBack = [...new Set(chordsToAddBack)];

                //     // remove them!
                //     for (let i2 = 0; i2 < chordsToAddBack.length; i2++){
                //         let chordToAddBack = chordsToAddBack[i2];

                //         this.removeAlternateChords([chordToAddBack]);
                //     }

                //     // get our dependent chord in there
                //     this.tryDependentChord(chordType, 0);

                //     // ... and lastly, try to add those back if possible
                //     for (let i2 = 0; i2 < chordsToAddBack.length; i2++){
                //         let chordToAddBack = chordsToAddBack[i2];

                //         this.tryDependentChord(chordToAddBack, 0);
                //     }


                //     return true;
                // }

            }

        }

        return harmonyState;
    }

    tryModalInterchange(iteration, harmonyState){
        // shuffle then iterate through chords

        for (let i = 0; i < this.state.modalInterchange.length; i++){
            let candidate = this.state.modalInterchange[i];

            // retrieve chord to replace
            let chordToReplace;
            for (let i2 = 0; i2 < harmonyState.chords.length; i2++){
                let chord = harmonyState.chords[i2];
                if (chord.beatNumber == candidate.beatNumber && chord.measureNumber == candidate.measureNumber){
                    chordToReplace = chord;
                }
            }

            if (iteration == 0){

                // check if chord being replaced is NOT in resolvedToIDs and is a base chord
                if (!this.resolvesToIDs.includes(chordToReplace.ID) && this.state.baseChords.map((baseChord) => baseChord.ID).includes(chordToReplace.ID)){

                    // if satisfied => add chord to chords & harmony & bass notes & activeIDs, remove chord to be replaced in chords & harmonyNotes & bassNotes & activeIDs, update lengths of all chords

                    return this.replaceChords([[candidate, chordToReplace]], harmonyState);
                }
            } else if (iteration == 1){

                // // figure out what this modal interchange will be replacing
                // let chordsToAddBack = [];

                // if (!this.state.baseChords.map((baseChord) => baseChord.ID).includes(chordToReplace.ID)){
                //     chordsToAddBack.push(chordToReplace.chordType);
                // }

                // let chordchord = chordToReplace;

                // while (this.resolvesToIDs.includes(chordchord.ID)){
                //     let chord = this.state.chords.filter((chord) => chord.resolvesToID == chordchord.ID)[0];
                //     chordsToAddBack.push(chord.chordType);
                //     chordchord = chord;

                // }

                // chordsToAddBack = [...new Set(chordsToAddBack)];


                // // remove them!
                // for (let i2 = 0; i2 < chordsToAddBack.length; i2++){
                //     let chordToAddBack = chordsToAddBack[i2];

                //     this.removeAlternateChords([chordToAddBack]);
                // }

                // // get modal interchange in there
                // this.tryModalInterchange(0);

                // // ... and lastly, try to add those back if possible
                // for (let i2 = 0; i2 < chordsToAddBack.length; i2++){
                //     let chordToAddBack = chordsToAddBack[i2];

                //     this.tryDependentChord(chordToAddBack, 0);
                // }


                // return true;
            }

        }

        return harmonyState;
    }

    updateLengthOfChords(chords, harmonyNotes, bassNotes){

        for (let i = 0; i < chords.length; i++){

            let prevChord = chords[i];
            let nextChord = chords[(i+1) % chords.length];
            if (prevChord.measureNumber == nextChord.measureNumber){
                prevChord.lengthInBeats = nextChord.beatNumber - prevChord.beatNumber;
            } else {
                prevChord.lengthInBeats = 4 - prevChord.beatNumber;
            }
        }

        harmonyNotes.forEach((note) => {
            note.length = chords.filter((chord) => chord.measureNumber == note.measureNumber && chord.beatNumber == note.deltaFromMeasure)[0].lengthInBeats;
        });
        bassNotes.forEach((note) => {
            note.length = chords.filter((chord) => chord.measureNumber == note.measureNumber && chord.beatNumber == note.deltaFromMeasure)[0].lengthInBeats;
        });
    }

    replaceChords(allChords, harmonyState){
        let harmonyNotes = JSON.parse(JSON.stringify(harmonyState.harmonyNotes));
        let bassNotes = JSON.parse(JSON.stringify(harmonyState.bassNotes));
        let chords = JSON.parse(JSON.stringify(harmonyState.chords));

        for (let i = 0; i < allChords.length; i++){
            let chordSet = allChords[i];
            let newChord = chordSet[0];
            let chordToReplace = chordSet[1];

            // harmonyNotes
            if (!!chordToReplace){
                harmonyNotes = harmonyNotes.filter((note) => note.measureNumber != chordToReplace.measureNumber || note.deltaFromMeasure != chordToReplace.beatNumber);
            }


            if (!!newChord){
                for (let i2 = 0; i2 < newChord.harmonyNotes.length; i2++){
                    let note = newChord.harmonyNotes[i2];
                    if (note.complexity == this.state.settings.voicingComplexity && note.closedOrOpen == this.state.settings.closedOrOpen){
                        harmonyNotes.push(...note.notes);
                    }
                }
            }

            // bassNotes
            if (!!chordToReplace){
                bassNotes = bassNotes.filter((note) => note.measureNumber != chordToReplace.measureNumber || note.deltaFromMeasure != chordToReplace.beatNumber);
            }

            if (!!newChord){
                bassNotes.push(newChord.bassNote);
            }

            // chords

            if (!!chordToReplace){
                chords = chords.filter((chord) => chord.measureNumber != chordToReplace.measureNumber || chord.beatNumber != chordToReplace.beatNumber);
            }

            if (!!newChord){
                chords.push(newChord);
            }

            chords.sort(function(a,b){
                if (a.measureNumber < b.measureNumber){
                    return -1;
                } else if (a.measureNumber > b.measureNumber){
                    return 1;
                } else if (a.beatNumber < b.beatNumber) {
                    return -1;
                } else {
                    return 1;
                }
            });


        }

        this.updateLengthOfChords(chords, harmonyNotes, bassNotes);

        return {
            harmonyNotes: harmonyNotes,
            bassNotes: bassNotes,
            chords: chords,
        };
    }


    removeAlternateChords(chordsToRemove, harmonyState){

        if (this.chordOnIdx == -1){
            this.clearAllNotes();
            this.setChordSelected(-1, null);
        }

        let chordz = [];

        for (let i = 0; i < chordsToRemove.length; i++){
            let chordToRemove = chordsToRemove[i];

            chordToRemove = harmonyState.chords.filter((chord) => chord.chordType.toLowerCase() == chordToRemove.toLowerCase())[0];

            if (!chordToRemove){return harmonyState;}

            this.resolvesToIDs.splice(this.resolvesToIDs.indexOf(chordToRemove.resolvesToID), 1);

            let baseChordToAdd = null;
            for (let i = 0; i < this.state.baseChords.length; i++){
                let chord = this.state.baseChords[i];
                if (chord.beatNumber == chordToRemove.beatNumber && chord.measureNumber == chordToRemove.measureNumber){
                    baseChordToAdd = chord;
                }
            }

            chordz.push([baseChordToAdd, chordToRemove]);
        }

        return this.replaceChords(chordz, harmonyState);

    }


    // removeAlternateChordAndPreserveDependents(chordType){

    //     let dependents = [];

    //     // figure out dependents

    //     this.removeAlternateChords(dependents);

    //     this.removeAlternateChords([chordType]);

    //     for (let i = 0; i < dependents.length; i++){
    //         let dependent = dependents[i];

    //         this.addAlternateChord(dependent);
    //     }

    // }

    refreshChordsMIDITrack(){

        // // write MIDI file for download
        // // Start with a new track
        // let track = new MidiWriter.Track();

        // // Define an instrument (optional):
        // track.addEvent(new MidiWriter.ProgramChangeEvent({instrument: 1}));

        // Add some notes:
        let timeToNoteMap = [];
        for (let i = 0; i < this.barsInLoop*this.notesInBar; i++){
            timeToNoteMap.push({notes: []});
        }

        this.addNotesToTimeToNoteMap(this.state.harmonyNotes, timeToNoteMap);
        this.addNotesToTimeToNoteMap(this.state.bassNotes, timeToNoteMap);

        let numberOfTracks = timeToNoteMap[0].notes.length;
        let tracks = [];
        for (let i = 0; i < numberOfTracks; i++){
            tracks.push(new MidiWriter.Track());
        }
        for (let i = 0; i < numberOfTracks; i++){
            let track = tracks[i];
            track.addEvent(new MidiWriter.ProgramChangeEvent({instrument: 1}));
        }

        // this.addNotesToMIDITrack(timeToNoteMap, track);
        this.addNotesToMIDITracks(timeToNoteMap, tracks);

        for (let i = 1; i < numberOfTracks; i++){
            tracks[0].mergeTrack(tracks[i]);
        }

        tracks[0].setTempo(this.state.tempo);

        // Generate a data URI
        var write = new MidiWriter.Writer(tracks[0]);
        // console.log(write.dataUri());
        this.setState({
            chordsMidiFilePath: write.dataUri(),
        });
    }

    addNotesToTimeToNoteMap(notes, timeToNoteMap){
        for (let i = 0; i < notes.length; i++){
            let note = notes[i];
            let noteCount = this.notesInBar * note.measureNumber + note.deltaFromMeasure;
            timeToNoteMap[noteCount]['notes'].push(note);
            // timeToNoteMap[noteCount]['length'] = note.length;
        }
    }

    addNotesToMIDITrack(timeToNoteMap, track){

        for (let i = 0; i < this.barsInLoop*this.notesInBar; i++){
            let notes = timeToNoteMap[i].notes;

            for (let n = 0; n < notes.length; n++){
                let note = notes[n];

                let duration;
                if (note.length == 1){
                    duration = '4';
                } else if (note.length == 2){
                    duration = '2';
                } else if (note.length == 3){
                    duration = 'd2'; // https://www.npmjs.com/package/midi-writer-js for explanation (d2 = dotted half)
                } else if (note.length == 4){
                    duration = '1';
                }
                if (note.length > 0){ // note.length == 0 => this is a rest, not a note
                    let tick = 128*i;
                    let midiNote = new MidiWriter.NoteEvent({pitch: note.note, duration: duration, velocity: note.velocity, startTick: tick});
                    track.addEvent(midiNote);
                }
            }

        }
    }

    addNotesToMIDITracks(timeToNoteMap, tracks){

        for (let i = 0; i < this.barsInLoop*this.notesInBar; i++){
            let notes = timeToNoteMap[i].notes;

            for (let n = 0; n < notes.length; n++){
                let note = notes[n];

                let duration;
                if (note.length == 1){
                    duration = '4';
                } else if (note.length == 2){
                    duration = '2';
                } else if (note.length == 3){
                    duration = 'd2'; // https://www.npmjs.com/package/midi-writer-js for explanation (d2 = dotted half)
                } else if (note.length == 4){
                    duration = '1';
                } else if (note.length == 0.5){
                    duration = '8';
                } else if (note.length == 0.25){
                    duration = '16';
                }
                if (note.length > 0){ // note.length == 0 => this is a rest, not a note
                    let midiNote = new MidiWriter.NoteEvent({pitch: note.note, duration: duration, velocity: note.velocity});
                    tracks[n].addEvent(midiNote);
                }
            }

        }
    }

    shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1));
            let temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
    }


}

export default Amadeus;