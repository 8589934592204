import React, {Component} from 'react';
import { Stage, Layer, Rect, Text, Circle, Line, Group } from 'react-konva';

import * as Tone from 'tone';

class Piano extends Component{

    constructor(props){
        super(props);

        this.state = {
            primaryScaleNotes: props.primaryScaleNotes,
            secondaryScaleNotes: props.secondaryScaleNotes,  
            activeColor: props.activeColor,
            activeColorSlightlyDarker: props.activeColorSlightlyDarker,
            correctColor: props.correctColor,
            incorrectColor: props.incorrectColor,
        }
 
        this.notes = ['C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'B'];
        this.octaves = 4;
        this.fullNotes = [];



        this.whiteWidth = 30;
        this.blackWidth = 20;
        this.whiteHeight = this.props.isMobile ? 70 : 120;
        this.blackHeight = this.props.isMobile ? 40 : 80;

        this.blackToWhiteWidthRatio = this.blackWidth / this.whiteWidth;

        this.yDelta = 0;

        this.synth = this.props.synth;
        this.isMobile = this.props.isMobile;

        // this.showText = this.isMobile;
        this.showText = false;
    }

    componentDidMount(){

        this.props.parent.forceUpdate();

        for (let o = 0; o < this.octaves; o++){
            for (let note of this.notes){
                this.fullNotes.push({note: note, octave: this.props.startingOctave+o});
            }
        }
    }
    
    render(){
        return (
            <div>
                <Stage style={{borderRadius: '8px', overflow: 'hidden'}} width={this.whiteWidth*this.octaves*7-2} height={this.whiteHeight}>
                    <Layer>
                        {this.fullNotes.map((note) => this.renderKey(note, note.octave-this.props.startingOctave))}
                    </Layer>
                </Stage>
            </div>
        )
    }

    isNotePressed(note){
        let x = this.props.pressedNotes.filter((item) => this.sameNoteAndOctave(item, note)).length > 0;
        return x;
    }

    isPrimaryScaleNote(note){
        let x = this.props.primaryScaleNotes.filter((item) => {
            return this.sameNote(item.note, note.note);
        }
        ).length > 0;
        return x;
    }

    enharmonicFlat(note){
        switch(note){
            case "C#": return "Db";
            case "D#": return "Eb";
            case "F#": return "Gb";
            case "G#": return "Ab";
            case "A#": return "Bb";
            default: return note;        
        }
    }

    isActiveHarmonyNote(note){
        let x = this.props.activeHarmonyNotes.filter((item) => this.sameNoteAndOctave(item, note)).length > 0;
        return x;
    }

    sameNoteAndOctave(note1, note2){

        note1 = {note: note1.slice(0, -1), octave: Number(note1.slice(-1))}

        let note1note = this.enharmonicFlat(note1.note);
        let note2note = this.enharmonicFlat(note2.note);

        return note1note == note2note && note1.octave == note2.octave;
    }

    sameNote(sharpOrFlatNote, note2){
        switch(note2){
            case 'C':
            case 'D':
            case 'E':
            case 'F':
            case 'G':
            case 'A':
            case 'B':
                return sharpOrFlatNote == note2;
            case 'Db':
            case 'C#':    
                return sharpOrFlatNote == 'Csharp' || sharpOrFlatNote == 'C#' || sharpOrFlatNote == 'Dflat' || sharpOrFlatNote == 'Db';
            case 'Eb':
            case 'D#':    
                return sharpOrFlatNote == 'Dsharp' || sharpOrFlatNote == 'D#' || sharpOrFlatNote == 'Eflat' || sharpOrFlatNote == 'Eb';
            case 'Gb':
            case 'F#':    
                return sharpOrFlatNote == 'Fsharp' || sharpOrFlatNote == 'F#' || sharpOrFlatNote == 'Gflat' || sharpOrFlatNote == 'Gb';
            case 'Ab':
            case 'G#':    
                return sharpOrFlatNote == 'Gsharp' || sharpOrFlatNote == 'G#' || sharpOrFlatNote == 'Aflat' || sharpOrFlatNote == 'Ab';
            case 'Bb':
            case 'A#':    
                return sharpOrFlatNote == 'Asharp' || sharpOrFlatNote == 'A#' || sharpOrFlatNote == 'Bflat' || sharpOrFlatNote == 'Bb';
            default:
                alert('no note matched :(');
        }
    }

    isSecondaryScaleNote(note){
        let x = this.state.secondaryScaleNotes.filter((item) => item == note).length > 0;
        return x;
    }

    handleMouseDown(event, text){
        this.props.handleKeyClick(text, this.props.parent);
    }

    handleMouseUp(event, text){
        this.props.handleKeyClickRelease(text, this.props.parent);
    }

    renderKey(note, octave){
        switch (note.note){
            case 'C':
                return this.renderLeftWhiteNote(note, 7*octave);
            case 'Db':
                return this.renderBlackNote(note, 7*octave + this.blackToWhiteWidthRatio);    
            case 'D':
                return this.renderMiddleWhiteNote(note, 7*octave+1);
            case 'Eb':
                return this.renderBlackNote(note, 7*octave+1 + this.blackToWhiteWidthRatio);    
            case 'E':
                return this.renderRightWhiteNote(note, 7*octave+2);
            case 'F':
                return this.renderLeftWhiteNote(note, 7*octave+3);
            case 'Gb':
                return this.renderBlackNote(note, 7*octave+3 + this.blackToWhiteWidthRatio);   
            case 'G':
                return this.renderMiddleWhiteNote(note, 7*octave+4);    
            case 'Ab':
                return this.renderBlackNote(note, 7*octave+4 + this.blackToWhiteWidthRatio); 
            case 'A':
                return this.renderMiddleWhiteNote(note, 7*octave+5);    
            case 'Bb':
                return this.renderBlackNote(note, 7*octave+5 + this.blackToWhiteWidthRatio); 
            case 'B':
                return this.renderRightWhiteNote(note, 7*octave+6); 
        }
    }

    renderLeftWhiteNote(note, left, text){
        return (
            <Group onMouseDown={(event) => this.handleMouseDown(event, text)} onMouseUp={(event) => this.handleMouseUp(event, text)} onMouseLeave={(event) => this.handleMouseUp(event, text)}>
            <Rect
                x={left*this.whiteWidth}
                y={this.yDelta}
                width={this.whiteWidth - this.blackWidth/2}
                height={this.blackHeight + 2}
                fill={this.getKeyColor(note)}
            />
            <Rect
                x={left*this.whiteWidth}
                y={this.yDelta + this.blackHeight + 1}
                width={this.whiteWidth}
                height={this.whiteHeight - this.blackHeight - 1}
                fill={this.getKeyColor(note)}
            />
            <Rect
                x={left*this.whiteWidth + this.whiteWidth - 2}
                y={this.yDelta + this.blackHeight}
                width={2}
                height={this.whiteHeight - this.blackHeight}
                fill="black"
            />
            {this.showText && <Text
                text={text}
                x={left*this.whiteWidth + 5}
                y={this.whiteHeight - 15}                
            />}
            </Group>
        );
    }

    renderMiddleWhiteNote(note, left, text){
        return (
            <Group onMouseDown={(event) => this.handleMouseDown(event, text)} onMouseUp={(event) => this.handleMouseUp(event, text)} onMouseLeave={(event) => this.handleMouseUp(event, text)}>
            <Rect
                x={left*this.whiteWidth + this.blackWidth/2}
                y={this.yDelta}
                width={this.whiteWidth - this.blackWidth}
                height={this.blackHeight + 2}
                fill={this.getKeyColor(note)}
            />
            <Rect
                x={left*this.whiteWidth}
                y={this.yDelta + this.blackHeight + 1}
                width={this.whiteWidth}
                height={this.whiteHeight - this.blackHeight - 1}
                fill={this.getKeyColor(note)}
            />
            <Rect
                x={left*this.whiteWidth + this.whiteWidth - 2}
                y={this.yDelta + this.blackHeight}
                width={2}
                height={this.whiteHeight - this.blackHeight}
                fill="black"
            />
            {this.showText && <Text
                text={text}
                x={left*this.whiteWidth + 5}
                y={this.whiteHeight - 15}                
            />}
            </Group>
        );
    }

    renderRightWhiteNote(note, left, text){
        return (
            <Group onMouseDown={(event) => this.handleMouseDown(event, text)} onMouseUp={(event) => this.handleMouseUp(event, text)} onMouseLeave={(event) => this.handleMouseUp(event, text)}>
            <Rect
                x={left*this.whiteWidth + this.blackWidth/2}
                y={this.yDelta}
                width={this.whiteWidth - this.blackWidth/2}
                height={this.blackHeight + 2}
                fill={this.getKeyColor(note)}
            />
            <Rect
                x={left*this.whiteWidth}
                y={this.yDelta + this.blackHeight + 1}
                width={this.whiteWidth}
                height={this.whiteHeight - this.blackHeight - 1}
                fill={this.getKeyColor(note)}
            />
            <Rect
                x={left*this.whiteWidth + this.whiteWidth - 2}
                y={this.yDelta}
                width={2}
                height={this.whiteHeight}
                fill="black"
            />
            {this.showText && <Text
                text={text}
                x={left*this.whiteWidth + 5}
                y={this.whiteHeight - 15}                
            />}
            </Group>
        );
    }

    renderPureWhiteNote(note, left, text){
        return (
            <Group onMouseDown={(event) => this.handleMouseDown(event, text)} onMouseUp={(event) => this.handleMouseUp(event, text)} onMouseLeave={(event) => this.handleMouseUp(event, text)}>
            <Rect
                x={left*this.whiteWidth}
                y={this.yDelta}
                width={this.whiteWidth-2}
                height={this.whiteHeight}
                fill={this.getKeyColor(note)}
            />
            {this.showText && <Text
                text={text}
                x={left*this.whiteWidth + 5}
                y={this.whiteHeight - 15}                
            />}
            </Group>
        );
    }

    renderBlackNote(note, left, text){
        return (
            <Group onMouseDown={(event) => this.handleMouseDown(event, text)} onMouseUp={(event) => this.handleMouseUp(event, text)} onMouseLeave={(event) => this.handleMouseUp(event, text)}>
            <Rect
                x={left*this.whiteWidth+1}
                y={this.yDelta}
                width={this.blackWidth-2}
                height={this.blackHeight}
                fill={this.getKeyColor(note, "black")}
                stroke="black"
                strokeWidth={2}
            />
            {this.showText && <Text
                text={text}
                x={left*this.whiteWidth + 5}
                y={this.blackHeight - 15}  
                fill="#B0B0B0"              
            />}
            </Group>
        );
    }


    getKeyColor(note, black){
        if (this.isNotePressed(note)){
            if (this.isPrimaryScaleNote(note) || this.props.primaryScaleNotes.length == 0){
                return this.state.correctColor;
            }
            else {
                return this.state.incorrectColor;
            }
        }
        else if (this.isActiveHarmonyNote(note)){
            return this.state.activeColorSlightlyDarker;
        }
        else if (this.isPrimaryScaleNote(note)){
            return this.state.activeColor;
        }
        else if (black=="black"){
            return '#000000';
        }
        else{
            return '#ffffff';
        }
    }
}

export default Piano;